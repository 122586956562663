/// <reference types="@lib/types" />
import "./types.d"
import autobind from "autobind-decorator";
import throttle from "lodash/throttle";
import { computed, observable, action } from "mobx";
import { RouterState, RouterStore } from "@lib/router";
import { IntlStore } from "@lib/intl";
import { ThemeStore } from "./stores/theme";
import { BillingStore } from "./stores/billing";
import { logger, CoreUtils } from "@lib/common";
import { ServiceStore } from "./stores/service";
import { NotificationsStore } from "./stores/notifications";
import { APIStore } from "./stores/api";
import { routeIsMatch } from "../routes";
import { generateStaffRestrictions } from "../react/ui/dashboard/views/staff";
import { config } from "../config";
import { OrderUtils } from "@lib/common";
import localStore from "store";
import { cloneDeepSafe } from "@lib/common";
import { PaymentMethods } from "@lib/common";
import cloneDeep from "lodash/cloneDeep";

interface GenericQueryCollection<T> {
	items: T[];
	count: number;
	page: number;
	chargeTotal?: number;
	feesTotal?: number;
	netTotal?: number;
}
interface GenericAsyncListCollection<T> {
	loading: boolean;
	error: string;
	items: T[];
}

type RestaurantsStore = GenericAsyncListCollection<T.API.DashboardRestaurantsResponseItem>;
type StaffStore = GenericAsyncListCollection<T.Schema.User.UserSchema>;
type APISStore = GenericAsyncListCollection<T.Schema.API.APISchema>;

interface OrdersView {
	layout: 0 | 1;
	boardSize: 2 | 3 | 4 | 5;
	hideUnconfirmed: boolean;
}

@autobind
export class RootStore {
	theme: ThemeStore;
	router: RouterStore;
	intl: IntlStore;
	api: APIStore;
	service: ServiceStore;
	notifications: NotificationsStore;
	billing: BillingStore;

	routeChangeCount: number = 0;

	@observable auth: AuthState;
	@observable loader: LoaderState;
	@observable view: ViewState;
	@observable ably: AblyState;

	@observable reseller: T.Schema.Reseller.ResellerSchema | null;
	@observable organisation: T.Schema.Organisation.OrganisationSchema | null;
	@observable website: T.Schema.Website.WebsiteSchema | null;
	@observable restaurants: RestaurantsStore;
	@observable staff: StaffStore;
	@observable apis: APISStore;

	@observable restaurant: T.Schema.Restaurant.RestaurantSchema | null;
	@observable restaurant_stock: T.Schema.RestaurantMenuStock.Schema | null;
	@observable restaurant_integration_base_apps:
		| T.Schema.Restaurant.Integrations.BaseApp[]
		| null;

	@observable	customers: GenericQueryCollection<T.Schema.Customer.CustomerSchema>;
	@observable customer: T.Schema.Customer.CustomerSchema | null;

	@observable ordersView: OrdersView;
	@observable ordersBoard: T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>;
	@observable orders: GenericQueryCollection<T.Schema.Order.OrderSchema>;
	@observable order: T.Schema.Order.OrderSchema | null;

	@observable bookings: GenericQueryCollection<T.Schema.Booking.BookingSchema>;
	@observable booking: T.Schema.Booking.BookingSchema | null;

    @observable onlinePaymentOrder: T.Schema.Stripe.StripeTransactions | null;
    @observable onlinePaymentOrders: GenericQueryCollection<T.Schema.Stripe.StripeTransactions>;
	@observable stripePayouts: GenericQueryCollection<T.Schema.Stripe.StripePayout>;

	// simply pass a parsed serialized state, the reason it's not auto parsed is to enable type safe construction if needed
	constructor() {
		this.auth = {
			type: null,
			item: null,
			token: null,
			decoded: null,
			fetching: false,
			error: null,
		};

		this.view = {
			breakpoint: "md",
			screen_width: 720,
			scroll_top: 0,
			sidenav_active: false,
		};

		this.loader = {
			active: true,
			opacity: 1,
			title: "Loading...",
			message:
				"This can take up to one minute the first time or if an update has been released",
		};

		this.ably = {
			status: "disconnected",
			connected_once: false,
			printers: [],
		};

		this.reseller = null;
		this.organisation = null;
		this.website = null;
		this.restaurants = observable({
			loading: false,
			error: "",
			items: [],
		});
		this.staff = observable({
			loading: false,
			error: "",
			items: [],
		});
		this.apis = observable({
			loading: false,
			error: "",
			items: [],
		});

		this.restaurant = null;
		this.restaurant_stock = null;
		this.restaurant_integration_base_apps = null;

		this.customers = {
			items: [],
			count: 0,
			page: 0,
		};
		this.customer = null;

		this.onlinePaymentOrders = {
			items: [],
			count: 0,
			page: 0,
		};
		this.onlinePaymentOrder = null;

		this.stripePayouts = {
			items: [],
			count: 0,
			page: 0,
		};

		const orderViewSettings = localStore.get("store-ordersView") || {};
		this.ordersView = {
			layout: orderViewSettings.layout
				? (parseInt(orderViewSettings.layout, 10) as 0 | 1)
				: 0,
			boardSize: orderViewSettings.boardSize
				? (parseInt(orderViewSettings.boardSize, 10) as 2 | 3 | 4 | 5)
				: 3,
			hideUnconfirmed:
				!!orderViewSettings.hideUnconfirmed,
		};
		this.ordersBoard = {
			loading: false,
			error: "",
			lists: {},
		};
		this.orders = {
			items: [],
			count: 0,
			page: 0,
		};
		this.order = null;

		this.bookings = {
			items: [],
			count: 0,
			page: 0,
		};
		this.booking = null;

		this.theme = new ThemeStore(this);
		this.router = new RouterStore(undefined, this.routeOnChange);
		// this.intl = new IntlStore(this);

		this.intl = new IntlStore({
			useReactModule: true,
		});

		this.notifications = new NotificationsStore(this);
		this.service = new ServiceStore(this);
		this.billing = new BillingStore(this);
		this.api = new APIStore(this, {
			auth_token_error: this.service.handle_auth_token_error,
		});

		this.routeOnChange(this.router.s);
		this.windowResize();
		this.windowScroll();
		window.addEventListener("resize", throttle(this.windowResize, 100));
		document.addEventListener("scroll", throttle(this.windowScroll, 50));
	}

	// LOGIN
	@action routeOnChange = (s: RouterState) => {
		try {
			const { restrictions, auth } = this;

			// INC
			this.routeChangeCount++;

			window.Intercom("update");

			// GET ROUTE
			const route = routeIsMatch(s.path);

			if (!route) {
				// NOT FOUND
				this.router.set404(true);
				document.title = "404 - Not Found";
			} else {
				// FOUND

				this.router.set404(false);

				document.title = route.title;

				if (this.routeChangeCount > 1 && route.auth && !auth.token) {
					// NOT AUTHENTICATED
					logger.info("ROUTE TAKE TO LOGIN");
					this.router.push("/login");
				} else if (
					auth.item &&
					auth.item.type === "staff" &&
					route.match &&
					route.match.rid &&
					restrictions.restaurants.indexOf(route.match.rid) === -1
				) {
					logger.info("ROUTE TAKE TO / RID");
					this.router.push("/");
				} else if (route.restriction_keys) {
					logger.info("ROUTE RESTRICTIONS");
					let is_restricted = true;

					for (const restriction_key of route.restriction_keys) {
						let available;
						const access_keys = restriction_key.split(".");

						if (access_keys.length === 3) {
							available =
								// @ts-ignore
								restrictions[access_keys[0]]?.[
										access_keys[1]
								]?.[access_keys[2]];
						} else {
							available =
								// @ts-ignore
								restrictions[access_keys[0]]?.[access_keys[1]];
						}

						if (available) {
							is_restricted = false;
							break;
						}
					}

					if (is_restricted) {
						logger.info("ROUTE RESTRICTIONS TAKE TO /");
						this.router.push("/");
					}
				}

				// SCROLL TOP
				const sr = document.getElementById("scroll-root");
				const noScrollPages = [
					"restaurant_bookings",
					"restaurant_orders",
					"restaurant_customers",
				];
				if (
					sr &&
					sr.scroll &&
					noScrollPages.indexOf(route.key) === -1
				) {
					sr.scroll({ top: 0, left: 0, behavior: "auto" });
				}
			}
		} catch (err) {
			logger.captureException(err, "ROUTE CHANGE ERROR");
			this.router.push("/");
		}
	};

	@computed get showMainUserSupport() {
		const user = this.auth.item;
		return this.isMainReseller && user && user.type !== "staff";
	}

	@computed get showMainSupport() {
		return this.isMainReseller;
	}

	// COMPUTED
	@computed get isMainReseller() {
		if (this.reseller) {
			return (
				["cloudwaitress", "cloudwaitress-test"].indexOf(
					this.reseller._id
				) !== -1
			);
		}
		return false;
	}

	@computed get isStaff() {
		if (this.auth.item) {
			return this.auth.item.type === "staff";
		}
		return false;
	}

	@computed get restrictions() {
		let restrictions;
		if (this.auth.item && this.auth.item.restrictions) {
			restrictions = cloneDeepSafe(this.auth.item.restrictions);
		} else {
			restrictions = generateStaffRestrictions();
		}

		const restaurantRestrictions = restrictions.restaurant;

		let restaurantSettingsEnabled = false;
		if (restrictions.restaurant.settings_detail) {
			const settingDetail = restrictions.restaurant.settings_detail;
			if (
				settingDetail.system ||
				settingDetail.services ||
				settingDetail.payments ||
				settingDetail.website ||
				settingDetail.integrations
			) {
				restaurantSettingsEnabled = true;
			}
		} else if (restrictions.restaurant.settings) {
			restaurantSettingsEnabled = true;
		}

		let onlinePaymentEnabled = false;
		if (restrictions.online_payment) {
			const onlinePaymentDetail = restrictions.online_payment;
			// for future extension
			if (onlinePaymentDetail.view_transaction)
				onlinePaymentEnabled = true;
		}

		const restaurantOrderViews: string[] = [];
		if (typeof restrictions.restaurant.orders_list === "undefined") {
			if (restrictions.restaurant.orders) {
				restaurantOrderViews.push("board");
				restaurantOrderViews.push("list");
			}
		} else {
			if (restrictions.restaurant.orders_board)
				restaurantOrderViews.push("board");
			if (restrictions.restaurant.orders_list)
				restaurantOrderViews.push("list");
		}

		const restaurantView =
			restaurantRestrictions.dashboard ||
			restaurantOrderViews.length > 0 ||
			restaurantRestrictions.bookings ||
			restaurantRestrictions.menus ||
			restaurantRestrictions.customers ||
			restaurantSettingsEnabled;

		const restaurantNotificationsEnabled =
			restaurantOrderViews.length > 0 ||
			restaurantRestrictions.bookings ||
			restaurantRestrictions.customers ||
			restaurantSettingsEnabled;

		return {
			...restrictions,
			_: {
				restaurantView,
				restaurantSettingsEnabled,
				restaurantOrderViews,
				restaurantNotificationsEnabled,
				onlinePaymentEnabled,
			},
		};
	}

	@computed get trialExpiry() {
		const r = this.reseller!;
		const organisation = this.organisation;
		if (
			organisation &&
			r.chargebee &&
			r.chargebee.subscription.trial_period_days
		) {
			return (
				organisation.created +
				1000 * 60 * 60 * 24 * r.chargebee.subscription.trial_period_days
			);
		}
		return 0;
	}

	@computed get trialExpired() {
		return Date.now() > this.trialExpiry; // 30 DAYS
	}

	@computed get isMapped() {
		const r = this.restaurant;
		if (!r) return false;
		return (
			r.location.map_data.type === "google_maps" ||
			r.location.map_data.type === "osm"
		);
	}

	@computed get storeURL() {
		const reseller = this.reseller;
		const r = this.restaurant;
		if (!r) {
			return "";
		}
		if (config.isTest) {
			return "http://localhost:3000";
		}
		return r.domain
			? `https://${r.domain}`
			: `https://${r.subdomain}.${reseller!.store_host}`;
	}

	// UTILS
	getPaymentMethodName = (method: string) => {
		const r = this.restaurant;

		const isBaseMethod = PaymentMethods.indexOf(method) !== -1;
		let paymentName = this.intl.i18n.t(
			`constants.payment.backend_method.${method}`
		);

		if (!r) {
			return isBaseMethod ? paymentName : method;
		}

		const paymentMethod = r.settings.payments[method];
		if (!isBaseMethod) {
			if (paymentMethod) {
				paymentName = paymentMethod.label || method;
			} else {
				paymentName = method;
			}
		}

		return paymentName;
	};

	// UPDATERS
	@action setAuth = (data: AuthState) => {
		this.auth = data;
	};
	@action updateAuth = (data: Partial<AuthState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof AuthState];
				if (value !== undefined) {
					// @ts-ignore
					this.auth[key as keyof AuthState] = value;
				}
			}
		}
	};

	@action setView = (data: ViewState) => {
		this.view = data;
	};
	@action updateView = (data: Partial<ViewState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof ViewState];
				if (value !== undefined) {
					// @ts-ignore
					this.view[key as keyof ViewState] = value;
				}
			}
		}
	};

	@action updateAbly = (data: Partial<AblyState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof AblyState];
				if (value !== undefined) {
					// @ts-ignore
					this.ably[key as keyof AblyState] = value;
				}
			}
		}
	};

	@action setLoader = (data: LoaderState) => {
		this.loader = data;
	};
	@action updateLoader = (data: Partial<LoaderState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof LoaderState];
				if (value !== undefined) {
					// @ts-ignore
					this.loader[key as keyof LoaderState] = value;
				}
			}
		}
	};
	@action toggleLoader = (data: boolean) => {
		this.loader.active = data;
	};

	@action setOrganisation = (
		obj: T.Schema.Organisation.OrganisationSchema | null
	) => {
		this.organisation = obj;
	};
	@action updateOrganisation = (
		data: Partial<T.Schema.Organisation.OrganisationSchema>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[key as keyof T.Schema.Organisation.OrganisationSchema];
				if (value !== undefined && this.organisation) {
					// @ts-ignore
					this.organisation[
						key as keyof T.Schema.Organisation.OrganisationSchema
					] = value;
				}
			}
		}
	};

	@action setWebsite = (obj: T.Schema.Website.WebsiteSchema | null) => {
		this.website = obj;
	};
	@action updateWebsite = (data: Partial<T.Schema.Website.WebsiteSchema>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof T.Schema.Website.WebsiteSchema];
				if (value !== undefined && this.website) {
					// @ts-ignore
					this.website[
						key as keyof T.Schema.Website.WebsiteSchema
					] = value;
				}
			}
		}
	};

	@action setRestaurant = (
		r: T.Schema.Restaurant.RestaurantSchema | null
	) => {
		this.restaurant = r;
		if (r) {
			this.intl.set({
				lng: "en",
				currency: {
					...r.settings.region.currency,
					step: CoreUtils.currency.precision_to_step(
						r.settings.region.currency.precision
					),
				},
				tz: r.settings.region.timezone,
				locale: r.settings.region.locale,
				formats: r.settings.region.formats,
			});
		}
	};
	@action updateRestaurant = (
		data: Partial<T.Schema.Restaurant.RestaurantSchema>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[key as keyof T.Schema.Restaurant.RestaurantSchema];
				if (value !== undefined && this.restaurant) {
					// @ts-ignore
					this.restaurant[
						key as keyof T.Schema.Restaurant.RestaurantSchema
					] = value;
				}
			}
		}

		if (data && data.settings && data.settings.region) {
			if (data.settings.region.timezone) {
				this.intl.update({ tz: data.settings.region.timezone });
			}
			if (data.settings.region.locale) {
				this.intl.update({ locale: data.settings.region.locale });
			}
			if (data.settings.region.currency) {
				this.intl.update({
					currency: {
						...data.settings.region.currency,
						step: CoreUtils.currency.precision_to_step(
							data.settings.region.currency.precision
						),
					},
				});
			}
			if (data.settings.region.formats && data.settings.region.currency) {
				this.intl.update({
					formats: data.settings.region.formats,
				});
			}
		}
	};
	@action updateRestaurants = (data: Partial<RestaurantsStore>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof RestaurantsStore];
				if (value !== undefined && this.restaurants) {
					// @ts-ignore
					this.restaurants[key as keyof RestaurantsStore] = value;
				}
			}
		}
	};
	@action updateRestaurantComplete = (
		_id: string,
		update: Partial<T.Schema.Restaurant.RestaurantSchema>
	) => {
		// UPDATE SINGLE
		if (this.restaurant && this.restaurant._id === _id) {
			this.updateRestaurant(update);
		}

		// UPDATE COLLECTION
		const items = [...this.restaurants.items];
		for (const [i, o] of items.entries()) {
			if (o._id === _id) {
				items[i] = { ...items[i], ...update };
				this.updateRestaurants({ items });
				break;
			}
		}
	};

	@action setRestaurantStock = (
		stock: T.Schema.RestaurantMenuStock.Schema
	) => {
		this.restaurant_stock = stock;
	};
	@action setRestaurantIntegrationBaseApps = (
		apps: T.Schema.Restaurant.Integrations.BaseApp[]
	) => {
		this.restaurant_integration_base_apps = apps;
	};
	@action getRestaurantStock = async (_id: string) => {
		try {
			const result = await this.api.menu_stock_find({ _id });
			if (result.outcome) {
				throw new Error(result.message);
			}
			this.restaurant_stock = result.stock;
		} catch (e) {
			logger.captureException(e);
		}
	};
	// Kounta API's
	@action getKountaSites = async (_id: any): Promise<any> => {
		try {
			const result = await this.api.get_kounta_sites(_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action getKountaPayments = async (_id: any): Promise<any> => {
		try {
			const result = await this.api.get_kounta_payments(_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action getKountaDeliveryProducts = async (
		_id: any,
		site_id: any
	): Promise<any> => {
		try {
			const result = await this.api.get_kounta_delivery_products(
				_id,
				site_id
			);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action getKountaRegisters = async (
		_id: any,
		site_id: any
	): Promise<any> => {
		try {
			const result = await this.api.get_kounta_registers(_id, site_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action generateMenu = async (_id: any, menu_id: string): Promise<any> => {
		try {
			const result = await this.api.generate_menu(_id, menu_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action generateMenuv2 = async (_id: any, menu_id: string): Promise<any> => {
		try {
			const result = await this.api.generate_menuv2(_id, menu_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action generateAbacusMenu = async (
		_id: any,
		menu_id: string
	): Promise<any> => {
		try {
			const result = await this.api.generate_menu_abacus(_id, menu_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action checkStatus = async (_id: any): Promise<any> => {
		try {
			const result = await this.api.check_status(_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};
	@action checkStatusAbacus = async (_id: any): Promise<any> => {
		try {
			const result = await this.api.check_status_abacus(_id);
			if (result.outcome) {
				throw new Error(result.message);
			}
			return result.data;
		} catch (e) {
			logger.captureException(e);
		}
	};

	@action updateStaff = (data: Partial<StaffStore>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof StaffStore];
				if (value !== undefined && this.staff) {
					// @ts-ignore
					this.staff[key as keyof StaffStore] = value;
				}
			}
		}
	};
	@action updateApis = (data: Partial<APISStore>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof APISStore];
				if (value !== undefined && this.apis) {
					// @ts-ignore
					this.apis[key as keyof APISStore] = value;
				}
			}
		}
	};

	@action setReseller = (data: T.Schema.Reseller.ResellerSchema | null) => {
		this.reseller = data;
	};
	@action updateReseller = (
		data: Partial<T.Schema.Reseller.ResellerSchema>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[key as keyof T.Schema.Reseller.ResellerSchema];
				if (value !== undefined && this.reseller) {
					// @ts-ignore
					this.reseller[
						key as keyof T.Schema.Reseller.ResellerSchema
					] = value;
				}
			}
		}
	};

	@action setOrder = (data: T.Schema.Order.OrderSchema | null) => {
		this.order = data;
	};
	@action updateOrder = (data: Partial<T.Schema.Order.OrderSchema>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof T.Schema.Order.OrderSchema];
				if (value !== undefined && this.order) {
					// @ts-ignore
					this.order[key as keyof T.Schema.Order.OrderSchema] = value;
				}
			}
		}
	};
	@action updateOrders = (
		data: Partial<GenericQueryCollection<T.Schema.Order.OrderSchema>>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[
					key as keyof GenericQueryCollection<T.Schema.Order.OrderSchema>
					];
				if (value !== undefined && this.orders) {
					// @ts-ignore
					this.orders[
						key as keyof GenericQueryCollection<T.Schema.Order.OrderSchema>
					] = value;
				}
			}
		}
	};
	@action updateOrdersBoard = (
		data: Partial<
			T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>
		>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[
					key as keyof T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>
					];
				if (value !== undefined && this.ordersBoard) {
					// @ts-ignore
					this.ordersBoard[
						key as keyof T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>
					] = value;
				}
			}
		}
	};
	@action updateOrderComplete = (order: T.Schema.Order.OrderSchema) => {
		const r = this.restaurant;

		if (r) {
			const tz = r.settings.region.timezone;

			// UPDATE SINGLE
			if (this.order && this.order._id === order._id) {
				this.order = order;
			}

			// UPDATE COLLECTION
			if (this.orders.items.length > 0) {
				for (const [i, o] of this.orders.items.entries()) {
					if (o._id === order._id) {
						this.orders.items[i] = order;
						break;
					}
				}
			}

			// UPDATE LISTBOARD
			const nextListId = OrderUtils.getOrderManagementStatus(order, tz);

			let listId = "";
			for (const key in this.ordersBoard.lists) {
				if (this.ordersBoard.lists[key]) {
					let index = -1;
					index = this.ordersBoard.lists[key].items.findIndex(
						(o) => o._id === order._id
					);
					if (index !== -1) {
						listId = key;
						break;
					}
				}
			}

			if (listId) {
				// SPLICE
				const itemIndex = this.ordersBoard.lists[
					listId
				].items.findIndex((o) => o._id === order._id);
				this.ordersBoard.lists[listId].items.splice(itemIndex, 1);

				// PUSH
				if (nextListId === "complete" || nextListId === "cancelled") {
					if (this.ordersBoard.lists[nextListId].items.length >= 5) {
						this.ordersBoard.lists[nextListId].items.pop();
					}
					this.ordersBoard.lists[nextListId].items.unshift(order);
				} else {
					this.ordersBoard.lists[nextListId].items.push(order);
					// tslint:disable-next-line
					this.ordersBoard.lists[
						nextListId
					].items = this.ordersBoard.lists[nextListId].items
						.slice()
						.sort(OrderUtils.sortFunctionByStatus(nextListId, tz));
				}
			}
		}
	};
	@action removeOrder = (_id: string) => {
		// UPDATE SINGLE
		if (this.order && this.order._id === _id) {
			this.setOrder(null);
		}
		// UPDATE COLLECTION
		const orders = [...this.orders.items];
		for (const [i, o] of orders.entries()) {
			if (o._id === _id) {
				orders.splice(i, 1);
				this.updateOrders({ items: orders });
				break;
			}
		}
		// UPDATE BOARD
		for (const key in this.ordersBoard.lists) {
			if (this.ordersBoard.lists[key]) {
				const index = this.ordersBoard.lists[key].items.findIndex(
					(o) => o._id === _id
				);
				if (index !== -1) {
					this.ordersBoard.lists[key].items.splice(index, 1);
					break;
				}
			}
		}
	};

	@action getOrder = async (orderId?: string) => {
		try {
			const { query } = this.router.s;
			const r = this.restaurant;
			const item = this.order;

			const queryId = query._id || query.order_id || orderId;

			const queryButNoItem = queryId && !item;
			const queryItemMismatch =
			queryId && item && item._id !== queryId;
			if (queryButNoItem || queryItemMismatch) {
				// CHECK ORDERS COLLECTION FOR ORDER
				let order = this.orders.items.find((o) => o._id === (queryId));
				if (order) {
					///@ts-ignore
					this.setOrder(cloneDeep(order));
					return;
				}

				// QUERY ORDER IF NOT IN COLLECTION
				let response = await this.api.order_find({ _id: queryId });

				if (response.outcome) {
					this.router.push(
						`${this.router.s.path}`
					);
					this.setOrder(null);
					return {
						outcome: 1,
						message: response.message
					}
				} else {
					let order = response.item;
					this.setOrder(order);
					return;
				}
			}
		} catch (e) {
			logger.captureException(e);
			return {
				outcome: 1,
				message: "Error finding order, please try again"
			}
		}
	}

	@action setBooking = (data: T.Schema.Booking.BookingSchema | null) => {
		this.booking = data;
	};
	@action updateBooking = (data: Partial<T.Schema.Booking.BookingSchema>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof T.Schema.Booking.BookingSchema];
				if (value !== undefined && this.booking) {
					// @ts-ignore
					this.booking[
						key as keyof T.Schema.Booking.BookingSchema
					] = value;
				}
			}
		}
	};
	@action updateBookingComplete = (item: T.Schema.Booking.BookingSchema) => {
		// UPDATE SINGLE
		this.setBooking(item);
		// UPDATE COLLECTION
		const items = [...this.bookings.items];
		for (const [i, o] of items.entries()) {
			if (o._id === item._id) {
				items[i] = item;
				this.updateBookings({ items });
				break;
			}
		}
	};
	@action updateBookings = (
		data: Partial<GenericQueryCollection<T.Schema.Booking.BookingSchema>>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[
					key as keyof GenericQueryCollection<T.Schema.Booking.BookingSchema>
					];
				if (value !== undefined && this.bookings) {
					// @ts-ignore
					this.bookings[
						key as keyof GenericQueryCollection<T.Schema.Booking.BookingSchema>
					] = value;
				}
			}
		}
	};
	@action removeBooking = (_id: string) => {
		// UPDATE SINGLE
		if (this.booking && this.booking._id === _id) {
			this.setOrder(null);
		}
		// UPDATE COLLECTION
		const items = [...this.bookings.items];
		for (const [i, o] of items.entries()) {
			if (o._id === _id) {
				items.splice(i, 1);
				this.updateBookings({ items });
				break;
			}
		}
	};

	@action updateCustomers = (
		data: Partial<GenericQueryCollection<T.Schema.Customer.CustomerSchema>>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[
					key as keyof GenericQueryCollection<T.Schema.Customer.CustomerSchema>
					];
				if (value !== undefined && this.customers) {
					// @ts-ignore
					this.customers[
						key as keyof GenericQueryCollection<T.Schema.Customer.CustomerSchema>
					] = value;
				}
			}
		}
	};

	@action setCustomer = (data: T.Schema.Customer.CustomerSchema | null) => {
		this.customer = data;
	};
	@action updateCustomer = (
		data: Partial<T.Schema.Customer.CustomerSchema>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[key as keyof T.Schema.Customer.CustomerSchema];
				if (value !== undefined && this.customer) {
					// @ts-ignore
					this.customer[
						key as keyof T.Schema.Customer.CustomerSchema
					] = value;
				}
			}
		}
	};
	@action updateCustomerComplete = (
		item: T.Schema.Customer.CustomerSchema
	) => {
		// UPDATE SINGLE
		this.setCustomer(item);
		// UPDATE COLLECTION
		const items = [...this.customers.items];
		for (const [i, o] of items.entries()) {
			if (o._id === item._id) {
				items[i] = item;
				this.updateCustomers({ items });
				break;
			}
		}
	};
	@action removeCustomer = (_id: string) => {
		// UPDATE SINGLE
		if (this.customer && this.customer._id === _id) {
			this.setCustomer(null);
		}
		// UPDATE COLLECTION
		const items = [...this.customers.items];
		for (const [i, o] of items.entries()) {
			if (o._id === _id) {
				items.splice(i, 1);
				this.updateCustomers({ items });
				break;
			}
		}
	};

    @action setOnlinePaymentOrder = (data: T.Schema.Stripe.StripeTransactions | null) => {
        this.onlinePaymentOrder = data;
    }

    @action updateOnlinePaymentOrder = (
		data: Partial<T.Schema.Stripe.StripeTransactions>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[key as keyof T.Schema.Stripe.StripeTransactions];
				if (value !== undefined && this.onlinePaymentOrder) {
					// @ts-ignore
					this.onlinePaymentOrder[
						key as keyof T.Schema.Stripe.StripeTransactions
					] = value;
				}
			}
		}
	};

    @action updateOnlinePaymentOrders = (
		data: Partial<GenericQueryCollection<T.Schema.Stripe.StripeTransactions>>
	) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[
						key as keyof GenericQueryCollection<T.Schema.Stripe.StripeTransactions>
					];
				if (value !== undefined && this.onlinePaymentOrders) {
					// @ts-ignore
					this.onlinePaymentOrders[
						key as keyof GenericQueryCollection<T.Schema.Stripe.StripeTransactions>
					] = value;
				}
			}
		}
	};

	@action updateStripePayouts = (data: Partial<GenericQueryCollection<T.Schema.Stripe.StripePayout[]>>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value =
					data[
					key as keyof GenericQueryCollection<T.Schema.Stripe.StripePayout>
					];
				if (value !== undefined && this.stripePayouts) {
					// @ts-ignore
					this.stripePayouts[
						key as keyof GenericQueryCollection<T.Schema.Stripe.StripePayout>
					] = value;
				}
			}
		}
	}


	@action windowResize = () => {
		const width = window.innerWidth;
		const breakpoint = CoreUtils.ui.breakpoint(width);
		this.view.screen_width = width;
		this.view.breakpoint = breakpoint;
	};
	@action windowScroll = () => {
		try {
			const h1 = window.pageYOffset;
			const h2 = document.documentElement
				? document.documentElement.scrollTop
				: 0;
			const h3 = document.body.scrollTop;
			this.view.scroll_top = Math.max(h1, h2, h3);
		} catch (e) {
			logger.captureException(e, "SCROLL FUNCTION ERROR");
		}
	};
}
