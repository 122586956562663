import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { DataCurrenciesList } from "@lib/common";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { PaymentBaseFields } from "./base";
import { LinkTag } from "@lib/components";
import { ListServicesOptions } from "@lib/common";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentGravity;

@inject("store")
@observer
export class SettingsFormPaymentsGravity extends MobxComponent<Props, State> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		console.log(r.settings.payments.gravity);
		this.initialValues = r.settings.payments.gravity || {
			enabled: false,
			label: "",
			services: [],
			gravity_oid: "",
			gravity_auth_key: "",
			gravity_environment:
				"https://api.emergepay.chargeitpro.com/virtualterminal/v1",
			gravity_assets: "https://assets.emergepay.chargeitpro.com",
		};
	}

	render() {
		const { showMainSupport } = this.injected.store;
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.gravity = values;
					const update = {
						$set: { "settings.payments.gravity": r.settings.payments.gravity },
					};
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage=""
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							{/* {showMainSupport && (
								<FormGroup>
									<LinkTag className="block font-semi-bold" target="_blank" href="https://support.cloudwaitress.com/how-to-guides/setup-stripe-payments">Read the setup guide</LinkTag>
								</FormGroup>
							)} */}

							<FastField
								name="enabled"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Enabled"
											help="Allows customers to pay using this method"
										>
											<Switch
												id="enable-switch"
												checked={field.value}
												onChange={(e) =>
													setFieldValue("enabled", e.target.checked)
												}
											/>
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FormGroup>
								<div>
									<div style={{ marginBottom: "10px" }}>
										<p
											style={{
												fontSize: "16px",
												fontWeight: 600,
												color: "red",
												marginBottom: "10px",
											}}
										>
											IMPORTANT
										</p>
										<p style={{ fontSize: "16px", lineHeight: "18px" }}>
											The domain of the restaurant must be added to the valid
											list of domains in the Gravity portal, or the payments
											will be rejected.
										</p>
									</div>
								</div>
							</FormGroup>

							<FastField
								name="gravity_oid"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup title="Gravity OID" help="Your Gravity OID">
											<Input
												type="text"
												{...field}
												autoSave="false"
												autoCorrect="false"
											/>
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="gravity_auth_key"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Gravity Auth Key"
											help="Your Gravity Auth key."
										>
											<Input
												type="text"
												{...field}
												autoSave="true"
												autoCorrect="false"
											/>
										</FormGroup>
									</React.Fragment>
								)}
							/>

							{/* <FastField
                name="gravity_environment"
                render={({ field }: any) => (
                  <React.Fragment>
                    <FormGroup
                      title="Gravity API Url"
                      help="Your Gravity API url. This can be the live url, or the test url."
                    >
                      <Input
                        type="text"
                        {...field}
                        autoSave="true"
                        autoCorrect="false"
                      />
                    </FormGroup>
                  </React.Fragment>
                )}
              /> */}

							{/* <FastField
                name="gravity_assets"
                render={({ field }: any) => (
                  <React.Fragment>
                    <FormGroup
                      title="Gravity Assets Url"
                      help="Your Gravity Assets url. This can be the live url, or the test url."
                    >
                      <Input
                        type="text"
                        {...field}
                        autoSave="true"
                        autoCorrect="false"
                      />
                    </FormGroup>
                  </React.Fragment>
                )}
              /> */}

							{/* <FastField
								name="services"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Services"
										help="Select which services this payment method will be restricted to. Leave empty to be usable by all services">
										<SelectAdv
											type="multi"
											value={field.value}
											onChange={(options: string[]) => {
												props.setFieldValue("services", options);
											}}
											options={ListServicesOptions} />
									</FormGroup>
								)}
							/> */}

							{/* <FastField
								name="label_delivery"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Delivery Label"
										help={`Override the label for this payment method during order checkout specifically for delivery orders. Defaults to 'Credit Card'`}>
										<Input {...field} type="text" value={field.value || ""} />
									</FormGroup>
								)}
							/> */}

							{/* <FastField
								name="currency"
								render={({ field }: any) => (
									<FormGroup
										title="Currency"
										help="Select the currency you would like to accept Checkout charges in. Make sure you are authorized to accept charges in that currency and that it is supported by Checkout">
										<SelectAdv
											type="single"
											options={DataCurrenciesList}
											value={field.value}
											onChange={(code: string) => setFieldValue("currency", code)}
										/>
									</FormGroup>
								)}
							/> */}

							{/* <FastField
								name="disable_email_receipt"
								render={({ field }: any) => (
									<FormGroup
										title="Disable E-Mail Receipt"
										help="Toggle this to disable Stripe payment receipts from being sent to your customer's e-mail">
										<Switch
											id="disable_email_receipt-switch"
											checked={field.value || false}
											onChange={(e) => setFieldValue("disable_email_receipt", e.target.checked)} />
									</FormGroup>
								)}
							/> */}

							{/* <FastField
								name="custom_payment_email"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Enable Custom Payment Form"
										help="Entering an e-mail address will enable the custom payment form at your-store-domain.com/online-payment">
										<Input
											{...field}
											type="email"
											placeholder="Enter E-Mail Address For Payment Notification"
											value={field.value || ""}
										/>
									</FormGroup>
								)}
							/> */}

							{/* <FastField
								name="services"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Services"
										help="Select which services this payment method will be restricted to. Leave empty to be usable by all services">
										<SelectAdv
											type="multi"
											value={field.value}
											onChange={(options: string[]) => {
												console.log({ options })
												setFieldValue("services", options);
											}}
											options={ListServicesOptions} />
									</FormGroup>
								)}
							/> */}

							<PaymentBaseFields
								defaultLabel="Credit Card"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button
								full={true}
								color="primary"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>
						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
