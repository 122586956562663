import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { DataCurrenciesList } from "@lib/common";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { PaymentBaseFields } from "./base";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentIpay88;

@inject("store") @observer
export class SettingsFormPaymentsIpay88 extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.payments.ipay88 || {
			enabled: false,
			label: "",
			services: [],
			merchant_code: "",
			merchant_key: "",
			currency: "",
		};
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.ipay88 = values;
					const update = { $set: { "settings.payments.ipay88": r.settings.payments.ipay88 } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="enabled"
								render={({ field }: any) => (
									<FormGroup
										title="Enabled"
										help="Allows customers to pay using this method">
										<Switch
											id="enable-switch"
											checked={field.value}
											onChange={(e) => setFieldValue("enabled", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="api_entry_url"
								render={({ field }: any) => (
									<FormGroup title="API Entry URL">
										<Input type="url" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="api_query_url"
								render={({ field }: any) => (
									<FormGroup title="API Re-Query URL">
										<Input type="url" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="payment_id"
								render={({ field }: any) => (
									<FormGroup title="Payment ID">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="merchant_code"
								render={({ field }: any) => (
									<FormGroup title="Merchant Code">
										<Input type="text" {...field} autoSave="false" autoCorrect="false" />
									</FormGroup>
								)}
							/>

							<FastField
								name="merchant_key"
								render={({ field }: any) => (
									<FormGroup
										title="Merchant Key">
										<Input type="text" {...field} autoSave="false" autoCorrect="false" />
									</FormGroup>
								)}
							/>

							<FastField
								name="currency"
								render={({ field }: any) => (
									<FormGroup
										title="Currency"
										help="Select the currency you would like to accept charges in. Make sure you are authorized to accept charges in this currency and that it is supported">
										<SelectAdv
											type="single"
											options={DataCurrenciesList}
											value={field.value}
											onChange={(code: string) => setFieldValue("currency", code)}
										/>
									</FormGroup>
								)}
							/>

							<PaymentBaseFields
								defaultLabel="iPay88"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
