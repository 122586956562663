import React, { useMemo } from 'react';
import _flatten from 'lodash/flatten';
import { MobXProviderContext } from 'mobx-react';
import { FastField, Field, FormikProps, FastFieldProps, FieldProps } from 'formik';

import { RestaurantUtils } from '@lib/common';
import { FreeItemPromo, Condition } from '../promos/type';
import { FormGroup, Input, ButtonGroup, FormTreeSelect } from '@lib/components';

type Props = {
  form: FormikProps<FreeItemPromo>;
  getFieldError: (
    form: FormikProps<FreeItemPromo>,
    field: string
  ) => T.ObjectAny | string | undefined | null;
};

export function ConditionTab({ form, getFieldError }: Props) {
  const { store } = React.useContext(MobXProviderContext);

  const menuTree = useMemo(() => {
    return RestaurantUtils.menu.getTreeFilter(store.restaurant!, () => true);
  }, [store.restaurant]);

  const conditionType = form.values.condition.type;
	
  const normalizeTreeData = (
    input: Condition['eligible_items'] | Condition['free_items']
  ) => {
    if (!input) return [];

    const restaurant: T.Schema.Restaurant.RestaurantSchema = store.restaurant!;
    const menus = restaurant.menus;
    let dishIds: string[] = [];

    for (const menuId of Object.keys(input)) {
      const selection = input[menuId];
      const menu = menus.find(m => m._id === menuId);
      if (!menu) continue;

      if (selection.menu.length > 0) {
        menu.categories.forEach(category =>
          category.dishes.forEach(dish => dishIds.push(dish._id))
        );
        continue;
      }

      if (selection.category.length > 0) {
        menu.categories.forEach(category => {
          if (selection.category.includes(category._id)) {
            category.dishes.forEach(dish => dishIds.push(dish._id));
          }
        });
      }

      if (selection.dish.length > 0) {
        dishIds = dishIds.concat(selection.dish);
      }
    }

    return dishIds;
  };

  return (
    <div className="p-4">
      <Field name="condition.type">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Type"
            help="Pick elgibility criteria to be either the cart quantity or the cart total"
            error={getFieldError(form, 'condition.type')}
          >
            <ButtonGroup
              size={'xs'}
              selected={field.value}
              options={[
                {
                  value: 'item_quantity',
                  name: 'Item Quantity',
                },
                {
                  value: 'order_amount',
                  name: 'Order Amount',
                },
              ]}
              buttonClassName="p-lr-2"
              onSelect={v => form.setFieldValue('condition.type', v.value)}
              width="auto"
            />
          </FormGroup>
        )}
      </Field>

      {conditionType === 'item_quantity' && (
        <FastField name="condition.eligible_items">
          {({ field }: FieldProps) => (
            <FormGroup
              title="Eligible Item"
              help="Select item that will entitle the customer to free item. You may select the menus, categories or specific items"
              error={getFieldError(form, 'condition.eligible_items')}
            >
              <FormTreeSelect
                id={`eligible_items`}
                selected={normalizeTreeData(field.value)}
                nodes={menuTree}
                typePriority={['menu', 'category', 'dish']}
                onChange={(_, prioritizedSelected) =>
                  form.setFieldValue(
                    'condition.eligible_items',
                    prioritizedSelected
                  )
                }
              />
            </FormGroup>
          )}
        </FastField>
      )}

      <Field name="condition.lower_limit">
        {({ field }: FieldProps) => (
          <FormGroup
            title={
              conditionType === 'item_quantity'
                ? 'Required Purchase Quantity (Min)'
                : 'Order Amount (Min)'
            }
            help={
							conditionType === 'item_quantity' 
								? "Will only allow free items if this minimum purchase quantity has been met. Default to 1" 
								: "Pick eligible criteria to be either the cart quantity or order amount."}
            optional={true}
            error={getFieldError(form, 'condition.lower_limit')}
          >
            <Input
              type="number"
              step={conditionType === 'item_quantity' ? 1 : 0.01}
              {...field}
            />
          </FormGroup>
        )}
      </Field>

      <Field name="condition.upper_limit">
        {({ field }: FieldProps) => (
          <FormGroup
            title={
              conditionType === 'item_quantity'
                ? 'Required Purchase Quantity (Max)'
                : 'Order Amount (Max)'
            }
            help={
							conditionType === 'item_quantity' 
								? "Will only allow free items if this maximum purchase quantity has been met. Default to 1" 
								: "Minumum amount to be entitled to free items."}
            optional={true}
            error={getFieldError(form, 'condition.upper_limit')}
          >
            <Input
              type="number"
              step={conditionType === 'item_quantity' ? 1 : 0.01}
              {...field}
            />
          </FormGroup>
        )}
      </Field>

      <FastField name="condition.free_items">
        {({ field }: FieldProps) => (
          <FormGroup
            title="Free Item"
            help="Select the free items to be offered to the customer"
            error={getFieldError(form, 'condition.free_items')}
          >
            <FormTreeSelect
              id={`free_items`}
              selected={normalizeTreeData(field.value)}
              nodes={menuTree}
              typePriority={['menu', 'category', 'dish']}
              onChange={(_, prioritizedSelected) =>
                form.setFieldValue('condition.free_items', prioritizedSelected)
              }
            />
          </FormGroup>
        )}
      </FastField>

      <FastField name="condition.free_quantity">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Free Quantity"
            help="The maximum quantity of items that can be redeemed for free with the above free items. Default to 1"
            optional={true}
            error={getFieldError(form, 'condition.free_quantity')}
          >
            <Input type="number" step={'1'} {...field} />
          </FormGroup>
        )}
      </FastField>
    </div>
  );
}
