import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { PaymentBaseFields } from "./base";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentBamboraNA;

@inject("store") @observer
export class SettingsFormPaymentsBamboraNa extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.payments.bambora_na || {
			enabled: false,
			label: "",
			services: [],
			merchant_id: "",
			passcode: "",
		};
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.bambora_na = values;
					const update = { $set: { "settings.payments.bambora_na": r.settings.payments.bambora_na } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => {
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="enabled"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Enabled"
											help="Allows customers to pay using this method">
											<Switch
												id="enable-switch"
												checked={field.value}
												onChange={(e) => setFieldValue("enabled", e.target.checked)} />
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="merchant_id"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Merchant ID"
											help="The merchant ID given by Bambora when setting up an account.">
											<Input type="text" {...field} autoSave="false" autoCorrect="false" />
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="passcode"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Passcode"
											help="The passcode given by Bambora when setting up an account.">
											<Input type="text" {...field} autoSave="true" autoCorrect="false" />
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<PaymentBaseFields
								defaultLabel="Bambora North America"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>
						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
