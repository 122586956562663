import {
	FormGroup,
	Switch,
	Input,
	Textarea,
	Button,
	RotateLoader,
	styled,
} from "@lib/components";
import { FastField, Field } from "formik";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { UI } from "../../../../../../../core/ui";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { PaymentBaseFields } from "./base";
import _isEmpty from "lodash/isEmpty";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutGooglePay;

const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`;

@inject("store")
@observer
export class SettingsFormPaymentsCheckoutGooglePay extends MobxComponent<
Props,
State
> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.payments.checkout_google_pay || {
			enabled: false,
			label: "",
			services: [],
			checkout_public_key: "",
			checkout_secret_key: "",
			google_pay_merchant_id: "",
			google_pay_merchant_name: "",
		};
	}

	validateRequiredField(value: string) {
		let error;
		if (_isEmpty(value)) {
			error = "This field value cannot be empty.";
		}
		return error;
	}

	validateCheckoutPublicKey(value: string) {
		let error;
		if (_isEmpty(value) || !value.startsWith("pk_")) {
			error = "Invalid Checkout.com public key provided.";
		}
		return error;
	}

	validateCheckoutSecretKey(value: string) {
		let error;
		if (_isEmpty(value) || !value.startsWith("sk_")) {
			error = "Invalid Checkout.com secret key provided.";
		}
		return error;
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.checkout_google_pay = values;
					const update = {
						$set: {
							"settings.payments.checkout_google_pay":
								r.settings.payments.checkout_google_pay,
						},
					};
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage=""
			>
				{({ form, error }) => {
					const { errors, isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="enabled"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Enabled"
											help="Allows customers to pay using this method"
										>
											<Switch
												id="enable-switch"
												checked={field.value}
												onChange={(e) =>
													setFieldValue("enabled", e.target.checked)
												}
											/>
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="checkout_secret_key"
								validate={this.validateCheckoutSecretKey}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Checkout Secret Key"
											help="Your Checkout secret API key, do not to share this with anyone"
										>
											<Input
												type="text"
												{...field}
												autoSave="false"
												autoCorrect="false"
											/>
											{errors.checkout_secret_key && (
												<ErrorBox>{errors.checkout_secret_key}</ErrorBox>
											)}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="checkout_public_key"
								validate={this.validateCheckoutPublicKey}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Checkout Public Key"
											help="Your Checkout public API key that will be used to initiate online charges"
										>
											<Input
												type="text"
												{...field}
												autoSave="true"
												autoCorrect="false"
											/>
											{errors.checkout_public_key && (
												<ErrorBox>{errors.checkout_public_key}</ErrorBox>
											)}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FormGroup>
								<div>
									<div style={{ marginBottom: "10px" }}>
										<p
											style={{
												fontSize: "16px",
												fontWeight: 600,
												color: "red",
												marginBottom: "10px",
											}}
										>
											Notes
										</p>
										<p style={{ fontSize: "14px", lineHeight: "18px" }}>
											To set up Google Pay with Checkout.com, you need an Google
											Developer Account, a valid domain and Checkout.com
											account. Please follow the guide{" "}
											<a
												href="https://docs.checkout.com/payment-methods/wallets/google-pay"
												target="_blank"
												rel="noopener"
											>
												<strong>here</strong>
											</a>{" "}
											to create essentials Google Pay credentials.
										</p>
									</div>
								</div>
							</FormGroup>

							<Field
								name="google_pay_merchant_id"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Google Pay Merchant ID"
											help="The Google Pay merchant identifier. You can find this value in Certificates, Identifiers & Profiles section of your Google Developer Account"
										>
											<Input
												type="text"
												{...field}
												autoSave="false"
												autoCorrect="false"
											/>
											{errors.google_pay_merchant_id && (
												<ErrorBox>{errors.google_pay_merchant_id}</ErrorBox>
											)}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<Field
								name="google_pay_merchant_name"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Google Pay Merchant Name"
											help="The Google Pay merchant name. You can find this value in Certificates, Identifiers & Profiles section of your Google Developer Account"
										>
											<Input
												type="text"
												{...field}
												autoSave="false"
												autoCorrect="false"
											/>
											{errors.google_pay_merchant_name && (
												<ErrorBox>{errors.google_pay_merchant_name}</ErrorBox>
											)}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<PaymentBaseFields
								defaultLabel="Google Pay (Checkout.com)"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button
								full={true}
								color="primary"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>
						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
