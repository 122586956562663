import React from 'react';
import shortid from 'shortid';
import cloneDeep from 'lodash/cloneDeep';
import { inject, observer } from 'mobx-react';

import { Button } from '@lib/components';
import { ATTRIBUTE_MAP } from './promos/constants';
import { GenericPromo, PromoType } from './promos/type';
import { getPromoType, promosByType } from './promos/util';
import { PromoList } from './promos/components/promo-list';
import { PromoItemModal } from './promos/components/promo-item-modal';
import { PromoCopyModal } from './promos/components/promo-copy-modal';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { PromoDeletionModal } from './promos/components/promo-deletion-modal';

interface Props {}
interface State {
  activeId: string | null;
  type: PromoType;

  copyId: string | null;
  copyLoading: boolean;
  removeId: string | null;
  removeLoading: boolean;
}

@inject('store')
@observer
export class SettingsPromos extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      type: null,
      activeId: null,
      copyId: null,
      copyLoading: false,
      removeId: null,
      removeLoading: false,
    };
  }

  getPromoList = (): GenericPromo[] => {
    const restaurant = this.injected.store.restaurant!;
    return [...restaurant.promos, ...(restaurant.free_item_promos || [])];
  };

  copy = async (copyId: string | null, type: PromoType) => {
    if (!copyId || !type) return;
    await this.saveRestaurant({
      successMsg: 'Promo copied',
      process: restaurant => {
        const promos = promosByType(restaurant, type);
        const promo = cloneDeep(promos.find(promo => promo._id === copyId));
        if (promo) {
          promo._id = shortid.generate();
          promo.name = `${promo.name} - Copy`;
          promo.code = `${promo.code}-COPY`;
        }
        const newPromos = promo ? promos.concat(promo) : promos;

        return {
          update: { $set: { [ATTRIBUTE_MAP[type]]: newPromos } },
        };
      },
      before: () => this.setState({ copyLoading: true }),
      onSuccess: () =>
        this.setState({ copyLoading: false, copyId: null, type: null }),
      onFail: () => this.setState({ copyLoading: false }),
      onError: () => this.setState({ copyLoading: false }),
      shouldUpdateStore: true,
    });
  };

  remove = async (removeId: string | null, type: PromoType) => {
    if (!removeId || !type) return;
    await this.saveRestaurant({
      successMsg: 'Promo deleted',
      process: restaurant => {
        const promos = promosByType(restaurant, type);
        const newPromos = promos.filter(promo => promo._id !== removeId);

        return {
          update: { $set: { [ATTRIBUTE_MAP[type]]: newPromos } },
        };
      },
      before: () => this.setState({ removeLoading: true }),
      onSuccess: () =>
        this.setState({ removeLoading: false, removeId: null, type: null }),
      onFail: () => this.setState({ removeLoading: false }),
      onError: () => this.setState({ removeLoading: false }),
      shouldUpdateStore: true,
    });
  };

  render() {
    const restaurant = this.injected.store.restaurant!;
    const { type, activeId, copyId, copyLoading, removeId, removeLoading } =
      this.state;

    return (
      <div className="p-4">
        <p className="lhp m-b-3">
          Promo codes can be entered by your customers during checkout to
          receive a discount
        </p>

        <Button
          color="primary"
          size="xs"
          onClick={() => this.setState({ type: 'discount' })}
        >
          Create Promo Code
        </Button>

        <Button
          className="m-l-2"
          color="primary"
          size="xs"
          onClick={() => this.setState({ type: 'free_item' })}
        >
          Create Free Item Promo
        </Button>

        <PromoList
          promos={this.getPromoList()}
          onCopy={(e, promo) => {
            e.stopPropagation();
            this.setState({
              copyId: promo._id,
              type: getPromoType(promo),
            });
          }}
          onRemove={(e, promo) => {
            e.stopPropagation();
            this.setState({
              removeId: promo._id,
              type: getPromoType(promo),
            });
          }}
          onActivate={promo =>
            this.setState({
              activeId: promo._id,
              type: getPromoType(promo),
            })
          }
        />

        <PromoItemModal
          restaurant={restaurant}
          type={type}
          activeId={activeId}
          shouldSkipOpening={!!(copyId || removeId)}
          onClose={() => this.setState({ activeId: null, type: null })}
        />

        <PromoCopyModal
          restaurant={restaurant}
          type={type}
          copyId={copyId}
          copyLoading={copyLoading}
          onCopy={id => this.copy(id, type)}
          onClose={() => this.setState({ copyId: null, type: null })}
          onCancel={() => this.setState({ copyId: null, type: null })}
        />

        <PromoDeletionModal
          restaurant={restaurant}
          type={type}
          removeId={removeId}
          removeLoading={removeLoading}
          onRemove={id => this.remove(id, type)}
          onClose={() => this.setState({ removeId: null, type: null })}
          onCancel={() => this.setState({ removeId: null, type: null })}
        />
      </div>
    );
  }
}
