import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import { MobxComponent } from "../../../../../mobx/components";
import {
	Button,
	Modal,
	ModalContent,
	ModalTitle,
	Protable,
	RotateLoader,
	styled,
} from "@lib/components";
import { config } from "../../../../../config";
import { exportColumns, FORMATS, cloneDeepSafe } from "@lib/common";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantUtils } from "@lib/common";
import { IconCircle } from "@lib/components";
import { RelativeTime } from "@lib/components";
import moment from "moment-timezone";
import { OrderIcon } from "@lib/components";
import { DragAndDropMultiple } from "./edit-report-column";
import { pull } from "lodash";

interface Props extends WithTranslation { }
interface State {
	customExportModal: boolean;
	activeCol: string[];
	tempColumns: { [key: string]: { name: string; active: boolean } };
	loading: boolean;
	orderExport: {
		columns: { [key: string]: { name: string; active: boolean } };
	};
}

const ButtonWrapper = styled("div")`
	text-align: center;
`;

@inject("store")
@observer
class RestaurantOrdersListClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			customExportModal: false,
			activeCol: [],
			tempColumns: {},
			loading: false,
			orderExport: {
				columns: {},
			},
		};
	}

	componentDidMount() {
		const restaurant = this.injected.store.restaurant!;

		let orderExport;
		if (restaurant.settings.order_export) {
			orderExport = cloneDeepSafe(restaurant.settings.order_export);

			//handle case with new Checkout Field column
			if (!orderExport.columns.Custom_Checkout_Fields) {
				orderExport.columns.Custom_Checkout_Fields = {
					name: "Custom Checkout Field",
					active: false,
				};
			}
		} else {
			orderExport = {
				columns: exportColumns,
			};
		}

		const tempColumns = orderExport.columns;

		let activeCol: string[] = [];
		Object.values(orderExport.columns).forEach((c) => {
			if (c.active) activeCol.push(c.name);
		});

		this.setState({ activeCol, tempColumns, orderExport });
	}

	openCustomExportModal = () => {
		this.setState({ customExportModal: true });
	};

	closeAppModal = () => {
		this.setState({ customExportModal: false });
	};

	updateColumnsOrder = (columnOrder: any) => {
		this.setState({ tempColumns: columnOrder });
	};

	updateActiveColumns = (colName: string) => {
		const { activeCol } = this.state;
		if (activeCol.includes(colName)) {
			pull(activeCol, colName);
		} else {
			activeCol.push(colName);
		}
		this.setState({ activeCol: activeCol });
	};

	handleSubmit = async () => {
		const { store } = this.injected;
		const r = store.restaurant!;
		const { activeCol } = this.state;
		const tempColumns = this.state.tempColumns;
		this.setState({ loading: true });

		Object.values(tempColumns).forEach((c) => {
			if (activeCol.includes(c.name)) {
				c.active = true;
			} else {
				c.active = false;
			}
		});

		const order_export_temp = {
			columns: tempColumns,
		};

		const apiResult = await store.api.restaurant_update({
			_id: r._id,
			update: {
				$set: {
					"settings.order_export": order_export_temp,
				},
			},
		});

		if (apiResult.outcome === 0) {
			r.settings.order_export = order_export_temp;
			this.setState({
				orderExport: order_export_temp,
				loading: false,
			});
			this.closeAppModal();
		}
	};

	render() {
		const { store, t, theme } = this.injected;
		const { restrictions } = store;
		const r = store.restaurant!;
		const rid = r._id;
		const orders = store.orders;
		const {
			customExportModal,
			activeCol,
			loading,
			orderExport,
		} = this.state;

		return (
			<>
				<Protable<T.Schema.Order.OrderSchema>
					region={r.settings.region}
					items={orders.items}
					count={orders.count}
					exportSettings={orderExport}
					openCustomExportModal={this.openCustomExportModal}
					exportUrl={`${config.urls.api
						}/exports/dashboard/orders?jwt=${store.auth.token}&rid=${store.restaurant!._id
						}`}
					screenWidth={store.view.screen_width}
					query={{
						sortKeys: [
							{ label: "Created", value: "created" },
							{ label: "Due", value: "ready_in.timestamp" },
							{ label: "Name", value: "customer.name" },
							{ label: "Total", value: "bill.total" },
						],
						filters: [
							{
								label: "Status",
								key: "status",
								values: [
									{
										label: "Un-Confirmed",
										value: "unconfirmed",
									},
									{ label: "Confirmed", value: "confirmed" },
									{ label: "Ready", value: "ready" },
									{ label: "On Route", value: "on_route" },
									{ label: "Complete", value: "complete" },
									{ label: "Cancelled", value: "cancelled" },
								],
							},
							{
								label: "Service",
								key: "config.service",
								values: [
									{ label: "Pickup", value: "pickup" },
									{ label: "Delivery", value: "delivery" },
									{ label: "Dine In", value: "dine_in" },
								],
							},
							{
								label: "Seen",
								key: "seen",
								values: [
									{ label: "Seen", value: true },
									{ label: "Un-Seen", value: false },
								],
							},
						],
					}}
					onClick={(order) => {
						store.router.push(
							`/restaurant/${rid}/orders?_id=${order._id}`
						);
						store.notifications.mark_read_object(
							"order",
							order._id
						);
					}}
					fetch={async (data) => {
						data.query.restaurant_id = store.restaurant!._id;
						const response = await store.api.orders_find(data);
						if (response.outcome) throw new Error(response.message);
						store.updateOrders({
							items: response.items,
							count: response.count,
							page: data.page,
						});
					}}
					rowAlert={(order) => order.status === "unconfirmed"}
					columns={[
						{
							title: (
								<div className="text-center width100">Type</div>
							),
							width: 80,
							render: (row) => {
								return (
									<IconCircle
										className="center"
										size={38}
										icon={
											<OrderIcon
												service={
													row.config
														.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
												}
											/>
										}
										background={RestaurantUtils.order.color(
											row.status
										)}
									/>
								);
							},
						},
						{
							title: "# / Name",
							render: (row) => (
								<div>
									<p className="big font-semi-bold m-b-1">
										{row.number}
									</p>
									<p>{row.customer.name}</p>
								</div>
							),
						},
						{
							title: "Phone",
							breakpoint: 1000,
							render: (row) => <span>{row.customer.phone}</span>,
						},
						{
							title: "Email",
							breakpoint: 1100,
							render: (row) => <span>{row.customer.email}</span>,
						},
						{
							title: "Due",
							breakpoint: 400,
							render: (row) => {
								if (row.config.due === "now") {
									if (
										row.ready_in &&
										row.ready_in.timestamp
									) {
										return (
											<span>
												{t("datetimeFromTimestamp", {
													value:
														row.ready_in.timestamp,
												})}
											</span>
										);
									}
									return <span>Now / ASAP</span>;
								}
								const { date, time } = row.config;
								const timestamp = moment
									.tz(
										`${date} ${time}`,
										FORMATS.moment.datetime,
										store.intl.s.tz
									)
									.valueOf();
								return (
									<span>
										{t("datetimeFromTimestamp", {
											value: timestamp,
										})}
									</span>
								);
							},
						},
						{
							title: "Placed",
							breakpoint: 500,
							render: (row) => (
								<RelativeTime timestamp={row.created} />
							),
						},
						{
							title: "Total",
							breakpoint: 600,
							hide:
								restrictions.misc &&
								restrictions.misc.hide_prices,
							render: (row) => (
								<span>
									{t("currency", { value: row.bill.total })}
								</span>
							),
						},
						{
							title: "Tips",
							breakpoint: 700,
							hide:
								restrictions.misc &&
								restrictions.misc.hide_prices,
							render: (row) => (
								<span>
									{t("currency", { value: row.bill.tip })}
								</span>
							),
						},
						{
							title: "# Items",
							breakpoint: 800,
							render: (row) => (
								<span>{(row.dishes || []).length}</span>
							),
						},
						{
							title: "Payment",
							breakpoint: 900,
							render: (row) => (
								<span>
									{store.getPaymentMethodName(
										row.payment.method
									)}
								</span>
							),
						},
						{
							title: "Status",
							breakpoint: 700,
							render: (row) => (
								<span>{t(`order.status.${row.status}`)}</span>
							),
						},
					]}
				/>

				{orderExport.columns && (
					<Modal
						width={"xl"}
						active={customExportModal}
						close={() => {
							this.closeAppModal();
						}}
					>
						<ModalTitle className="round-top">
							<h4>Pick Report Columns</h4>
						</ModalTitle>

						<ModalContent>
							<DragAndDropMultiple
								columnsData={orderExport.columns}
								chunk={11}
								activeCol={activeCol}
								updateActiveCol={this.updateActiveColumns}
								updateColumnsOrder={this.updateColumnsOrder}
							/>
							<ButtonWrapper>
								<Button
									color="primary-inverse"
									size="lg"
									onClick={this.handleSubmit}
									className="m-r-2"
								>
									<span
										style={{
											alignItems: "center",
											display: "flex",
										}}
									>
										Save
										{loading && (
											<RotateLoader
												className="m-l-2"
												size={2}
												color={
													theme.colors.primary_text
												}
											/>
										)}
									</span>
								</Button>
								<Button
									color="primary-inverse"
									size="lg"
									onClick={this.closeAppModal}
								>
									Cancel
								</Button>
							</ButtonWrapper>
						</ModalContent>
					</Modal>
				)}
			</>
		);
	}
}

// @ts-ignore
export const RestaurantOrdersList = withTheme(
	withTranslation()(RestaurantOrdersListClass)
);
