import * as React from "react";
import { FastField, Field } from "formik";
import { FormGroup, styled } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { Textarea } from "@lib/components";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { PaymentBaseFields } from "./base";
import _isEmpty from 'lodash/isEmpty';

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentRazorPay;

const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`

@inject("store") @observer
export class SettingsFormPaymentsRazorPay extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.payments.razor_pay || {
			enabled: false,
			label: "",
			services: [],
			razor_pay_public_key: "",
			razor_pay_secret_key: ""
		};
	}

	validateRequiredField(value: string) {
		let error;
		if (_isEmpty(value)) {
			error = 'This field value cannot be empty.'
		}
		return error;
	}

	validateRazorPayPublicKey(value: string) {
		let error;
		if (_isEmpty(value) || !value.startsWith('rzp_')) {
			error = 'Invalid RazorPay.com public key provided.'
		}
		return error;
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.razor_pay = values;
					const update = { $set: { "settings.payments.razor_pay": r.settings.payments.razor_pay } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => {
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { errors, isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="enabled"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Enabled"
											help="Allows customers to pay using this method">
											<Switch
												id="enable-switch"
												checked={field.value}
												onChange={(e) => setFieldValue("enabled", e.target.checked)} />
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="razor_pay_secret_key"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Razor Pay Secret Key"
											help="Your Razor Pay secret API key, do not to share this with anyone">
											<Input type="text" {...field} autoSave="false" autoCorrect="false" />
											{errors.razor_pay_secret_key && <ErrorBox>{errors.razor_pay_secret_key}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="razor_pay_public_key"
								validate={this.validateRazorPayPublicKey}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Razor Pay Public Key"
											help="Your Razor Pay public API key that will be used to initiate online charges">
											<Input type="text" {...field} autoSave="true" autoCorrect="false" />
											{errors.razor_pay_public_key && <ErrorBox>{errors.razor_pay_public_key}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FormGroup>
								<div>
									<div style={{ marginBottom: "10px" }}>
										<p
											style={{
												fontSize: "16px",
												fontWeight: 600,
												color: "red",
												marginBottom: "10px",
											}}
										>
											Notes
										</p>
										<p style={{ fontSize: "14px", lineHeight: "18px" }}>
											To set up Razor Pay with RazorPay.com, you need to follow the guide <a href="https://razorpay.com/docs/payment-gateway/dashboard-guide/settings/api-keys/" target="_blank" rel="noopener"><strong>here</strong></a> to generate key.
										</p>
									</div>
								</div>
							</FormGroup>

							<PaymentBaseFields
								defaultLabel="Razor Pay (RazorPay.com)"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
