import { hot } from "react-hot-loader";
import * as React from "react";
import { MobxComponent } from "../mobx/components";
import { inject, observer } from "mobx-react";
import { logger } from "@lib/common";
import CoverLoader from "./components/loader/CoverLoader";
import Route from "./components/router/Route";
import { AuthLogin } from "./ui/auth/views/login";
import { AuthRegister } from "./ui/auth/views/register";
import { AuthPasswordResetInit } from "./ui/auth/views/password_reset_init";
import { AuthPasswordResetChange } from "./ui/auth/views/password_reset_change";
import { AuthSSO } from "./ui/auth/views/sso";
import { DashboardRestaurants } from "./ui/dashboard/views/restaurants";
import { DashboardWebsite } from "./ui/dashboard/views/website";
import { DashboardStaff } from "./ui/dashboard/views/staff";
import { DashboardAPI } from "./ui/dashboard/views/api";
import { LayoutDashboard } from "./ui/dashboard/layout";
import { LayoutRestaurant } from "./ui/restaurant/layout";
import { RestaurantDashboard } from "./ui/restaurant/views/dashboard/dashboard";
import { RestaurantOrders } from "./ui/restaurant/views/orders";
import { RestaurantConnect } from "./ui/restaurant/views/connect";
import { RestaurantBookings } from "./ui/restaurant/views/bookings";
import { RestaurantCustomers } from "./ui/restaurant/views/customers";
import { RestaurantMenus } from "./ui/restaurant/views/menus/menus";
import { RestaurantSettings } from "./ui/restaurant/views/settings/settings";
import { Link } from "./components/router/Link";
import * as Sentry from "@sentry/browser";

@inject("store") @observer
class App extends MobxComponent<{}, {}> {

	componentDidMount() {
		// FIRST MOUNT POINT FOR ENTIRE APPLICATION
		logger.info("APPLICATION MOUNTED");
		this.injected.store.service.init();
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		Sentry.withScope((scope) => {
			Object.keys(errorInfo).forEach((key) => {
				scope.setExtra(key, errorInfo[key as keyof React.ErrorInfo]);
			});
			Sentry.captureException(error);
		});
	}

	render404 = () => {
		return (
			<div className="width100 flex-center-vertical" style={{ height: "90vh" }}>
				<div
					style={{ fontSize: "60px" }}
					className="m-b-4">
					😕
				</div>
				<h1 className="m-b-4">404 - Page Not Found</h1>
				<p className="big">Sorry, this page does not exist. Go back to your <Link to="/">dashboard</Link> or <Link to="/login">login</Link></p>
			</div>
		);
	}

	render() {
		const { store } = this.injected;
		const { reseller, view, router } = store;
		if (!reseller) {
			return (
				<main className="">
					<CoverLoader />
				</main>
			);
		}
		return (
			<main>

				<CoverLoader />

				<div id="scroll-root" style={{ paddingLeft: view.sidenav_active ? `${store.theme.s.side_nav.width}px` : 0 }}>

					<Route pathKey="login">
						<AuthLogin />
					</Route>

					<Route pathKey="register">
						<AuthRegister />
					</Route>

					<Route pathKey="password_reset_init">
						<AuthPasswordResetInit />
					</Route>

					<Route pathKey="password_reset_change">
						<AuthPasswordResetChange />
					</Route>

					<Route pathKey="sso">
						<AuthSSO />
					</Route>

					<Route auth={true} pathKey={["home", "website", "staff", "developer"]}>
						<LayoutDashboard>
							<Route pathKey="home">
								<DashboardRestaurants />
							</Route>
							<Route pathKey="website">
								<DashboardWebsite />
							</Route>
							<Route pathKey="staff">
								<DashboardStaff />
							</Route>
							<Route pathKey="developer">
								<DashboardAPI />
							</Route>
						</LayoutDashboard>
					</Route>

					<Route auth={true} pathKey={[
						"restaurant_dashboard",
						"restaurant_orders",
						"restaurant_bookings",
						"restaurant_customers",
						"restaurant_menus",
						"restaurant_settings",
						"restaurant_stripe_connect",
					]}>
						<LayoutRestaurant>
							<Route pathKey="restaurant_dashboard">
								<RestaurantDashboard />
							</Route>
							<Route pathKey="restaurant_orders">
								<RestaurantOrders />
							</Route>
							<Route pathKey="restaurant_bookings">
								<RestaurantBookings />
							</Route>
							<Route pathKey="restaurant_customers">
								<RestaurantCustomers />
							</Route>
							<Route pathKey="restaurant_menus">
								<RestaurantMenus />
							</Route>
							<Route pathKey="restaurant_settings">
								<RestaurantSettings />
							</Route>
							<Route pathKey="restaurant_stripe_connect">
								<RestaurantConnect />
							</Route>
						</LayoutRestaurant>
					</Route>

					{router.is404 && this.render404()}

				</div>

			</main>
		);

	}

}

export default hot(module)(App);
