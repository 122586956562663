import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { DataCurrenciesList } from "@lib/common";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { PaymentBaseFields } from "./base";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentPesapal;

@inject("store") @observer
export class SettingsFormPaymentsPesapal extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.payments.pesapal || {
			enabled: false,
			label: "Pesapal",
			services: [],
			testing: false,
			consumer_key: "",
			consumer_secret: "",
			currency: "",
		};
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.pesapal = values;
					const update = { $set: { "settings.payments.pesapal": r.settings.payments.pesapal } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FastField
								name="enabled"
								render={({ field }: any) => (
									<FormGroup
										title="Enabled"
										help="Allows customers to pay using this method">
										<Switch
											id="enable-switch"
											checked={field.value}
											onChange={(e) => setFieldValue("enabled", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="testing"
								render={({ field }: any) => (
									<FormGroup
										title="Testing Environment"
										help="Enable this to use the Pesapal demo environment to test and experiment with your integration">
										<Switch
											id="testing-switch"
											checked={field.value || false}
											onChange={(e) => setFieldValue("testing", e.target.checked)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="consumer_key"
								render={({ field }: any) => (
									<FormGroup title="Consumer Key" help="Your Pesapal consumer key. For test environment, you will find it on your admin dashboard. For live environment, your key will have been emailed to you">
										<Input type="text" {...field} autoSave="false" autoCorrect="false" />
									</FormGroup>
								)}
							/>

							<FastField
								name="consumer_secret"
								render={({ field }: any) => (
									<FormGroup
										title="Consumer Secret" help="Your Pesapal consumer secret. For test environment, you will find it on your admin dashboard. For live environment, your secret will have been emailed to you">
										<Input type="text" {...field} autoSave="false" autoCorrect="false" />
									</FormGroup>
								)}
							/>

							<FastField
								name="currency"
								render={({ field }: any) => (
									<FormGroup
										title="Currency"
										help="Select the currency you would like to accept charges in. Make sure you are authorized to accept charges in this currency and that it is supported">
										<SelectAdv
											type="single"
											options={DataCurrenciesList}
											value={field.value}
											onChange={(code: string) => setFieldValue("currency", code)}
										/>
									</FormGroup>
								)}
							/>

							<PaymentBaseFields
								defaultLabel="Pesapal"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
