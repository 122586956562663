import * as React from "react";
import { styled, css } from "@lib/components";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> { }

const Content = styled("div")`
  padding: 20px 30px;
  margin-top: ${({ theme }) => theme.top_nav.height + 30}px;
  margin-bottom: ${({ theme }) => theme.top_nav.height + 5}px;
  width: 100%;
`;

export const RestaurantLayoutContent = (props: Props) => <Content {...props} />;
