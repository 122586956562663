import * as React from "react";
import {
	ButtonGroup,
	SelectAdv,
	Button,
	Box,
	BoxHeading,
	BoxSection,
	Content,
	Tooltip as CwTooltip,
	RotateLoader,
	DropDown,
	DetailRowList,
} from "@lib/components";

import { FaChevronLeft, FaChevronRight, FaChevronDown } from "react-icons/fa";
import { AiOutlineInfoCircle, AiOutlineMail } from "react-icons/ai";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import styled, { withTheme } from "styled-components";
import moment from "moment-timezone";
import { logger, RestaurantUtils, Untrusive } from "@lib/common";
import { withTranslation, WithTranslation } from "react-i18next";
import {
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
	LineChart,
	Line,
	Label,
	LabelList,
} from "recharts";
import _capitalize from "lodash/capitalize";
import _uniq from "lodash/uniq";
import { getOptionalOverviewReportEmail } from "../../../../utils/overview_report_email";
import { UI } from "../../../../../core/ui";

type Period = "day" | "week" | "month" | "year";

const PStyled = styled("p")`
	line-height: 1.4;
	padding: 0 1.2rem;
	text-align: left;
`;

interface Props extends WithTranslation {}
interface State {
	start: number;
	end: number;
	period: Period;
	chart: "sales" | "orders";
	filtersActive: boolean;
	sendEmailActive: boolean;
	counts: "item" | "category" | "tax";
	error: boolean;
	data: T.API.DashboardReportsBasicResponse | null;
	emails: string[];
	emailOptions: string[];
	isSendingEmail: boolean;
}

@inject("store")
@observer
class RestaurantDashboardClass extends MobxComponent<Props, State> {
	mounted = false;

	constructor(props: Props) {
		super(props);

		const { store } = this.injected;
		const r = store.restaurant!;
		const emailStore = r.settings.notifications.email.store;
		const optionalOverviewReportEmail =
			getOptionalOverviewReportEmail(emailStore);

		const overviewReportEmails = emailStore.overview_report_emails || [];

		const emails =
			overviewReportEmails.length > 0
				? overviewReportEmails
				: optionalOverviewReportEmail;

		const emailOptions = _uniq([...emails, ...optionalOverviewReportEmail]);

		this.state = {
			...this.getTimeRange(Date.now(), "day"),
			chart: "sales",
			filtersActive: false,
			sendEmailActive: false,
			counts: "item",
			error: false,
			data: null,
			emails,
			emailOptions,
			isSendingEmail: false,
		};
	}

	componentDidMount() {
		this.mounted = true;
		this.query();
	}

	componentWillUnmount(): void {
		this.mounted = false;
	}

	query = async () => {
		try {
			this.setState({ error: false, data: null });
			const { store } = this.injected;
			const { start, end, period } = this.state;
			const r = store.restaurant!;
			const data = await this.injected.store.api.reports_basic({
				restaurant_id: r._id,
				start: start,
				end: end,
				period: period,
			});
			if (data.outcome && this.mounted) {
				this.setState({
					error: true,
					data: null,
				});
			} else if (this.mounted) {
				this.setState({ data });
			}
		} catch (e) {
			logger.captureException(e);
			if (this.mounted) {
				this.setState({
					error: true,
					data: null,
				});
			}
		}
	};

	setTimePeriod = (period: Period) => {
		this.setState(
			{
				...this.getTimeRange(Date.now(), period),
			},
			this.query
		);
	};

	getTimeRange = (start: number, period: Period) => {
		const { store } = this.injected;
		const r = store.restaurant!;
		const tz = r.settings.region.timezone;
		const momentStart = moment.tz(start, tz).startOf(period);
		const momentEnd = moment.tz(start, tz).endOf(period);
		return {
			start: momentStart.valueOf(),
			end: momentEnd.valueOf(),
			period: period,
		};
	};

	changeTimeRange = (direction: "forward" | "backward") => {
		const tz = this.injected.store.restaurant!.settings.region.timezone;
		const { start, period } = this.state;
		const fn = direction === "forward" ? "add" : "subtract";
		const next = moment.tz(start, tz)[fn](1, period).valueOf();
		this.setState(
			{
				...this.getTimeRange(next, period),
			},
			this.query
		);
	};

	reset = () => {
		this.setTimePeriod("day");
	};

	dropdownFilterContent = (period: Period) => {
		return (
			<div className="p-3">
				<div className="flex-l-r-center">
					<div className="text-left">
						<p className="font-semi-bold m-b-2 small">Date Range</p>
						<ButtonGroup
							size={"xs"}
							width={66}
							baseColor="white"
							onSelect={(selected) =>
								this.setTimePeriod(selected.value as Period)
							}
							selected={period}
							options={[
								{ value: "day", name: "Day" },
								{ value: "week", name: "Week" },
								{
									value: "month",
									name: "Month",
								},
								{ value: "year", name: "Year" },
							]}
						/>
					</div>

					<div className="text-right">
						<p className="font-semi-bold m-b-2 small">
							Change Time Range
						</p>
						<ButtonGroup
							size={"xs"}
							width={40}
							baseColor="white"
							onSelect={(s) =>
								this.changeTimeRange(
									s.value as "forward" | "backward"
								)
							}
							options={[
								{
									value: "backward",
									name: <FaChevronLeft />,
								},
								{
									value: "forward",
									name: <FaChevronRight />,
								},
							]}
						/>
					</div>
				</div>
			</div>
		);
	};

	sendEmail = async () => {
		const { store } = this.injected;

		try {
			Untrusive.start();
			this.setState({ isSendingEmail: true });

			const { start, end, period, emails } = this.state;
			const r = store.restaurant!;

			// await new Promise((resolve, reject) => setTimeout(reject, 2000));
			await store.api.send_overview_report_email({
				restaurant_id: r._id,
				start: start,
				end: end,
				period: period,
				emails,
			})

			UI.notification.success("Overview Report has been sent!");
			this.setState({ sendEmailActive: false });
		} catch (e) {
			logger.captureException(e);
			UI.notification.error(
				"An error occurred, try again soon or contact us",
				{ timeout: 6000 }
			);
		} finally {
			Untrusive.stop();
			this.setState({ isSendingEmail: false });
		}
	};

	renderEmailSelect = () => {
		const { emails, emailOptions, isSendingEmail } = this.state;

		if (emailOptions.length === 0) {
			return (
				<div className="p-2">
					<PStyled>No Email Address was found!</PStyled>
					<PStyled>
						Please visit there and enter up to 4 emails:
					</PStyled>
					<PStyled>
						<strong>
							Settings &gt; System &gt; E-Mail Notifications &gt;
							Overview Report E-Mail
						</strong>
					</PStyled>
				</div>
			);
		}

		const emailOptionsLabel = emailOptions.map((address) => ({
			label: address,
			value: address,
		}));

		return (
			<div className="p-3">
				<SelectAdv
					type="multi"
					value={emails}
					onChange={(options: string[]) => {
						this.setState({ emails: options });
					}}
					options={emailOptionsLabel}
				/>

				<div className="p-tb-2 lhp">
					Pick up to 4 e-mail addresses to send Overview Report.
					<CwTooltip
						width={450}
						position="bottom"
						offset={10}
						text={
							<div>
								<PStyled>
									You can select from Notification Reply-To
									Email and Notification Email Addresses (if
									any).
								</PStyled>
								<PStyled>
									If you also want to use other emails, please
									visit:
								</PStyled>
								<PStyled>
									<strong>
										Settings &gt; System &gt; E-Mail
										Notifications &gt; Overview Report
										E-Mail
									</strong>
								</PStyled>
								<PStyled>and enter up to 4 emails.</PStyled>
							</div>
						}
					>
						<AiOutlineInfoCircle className="m-l-1" />
					</CwTooltip>
				</div>
				<Button
					full={true}
					color="primary-inverse"
					disabled={isSendingEmail || emails.length === 0}
					onClick={this.sendEmail}
				>
					{isSendingEmail && <RotateLoader size={2} color="white" />}
					{!isSendingEmail && "Send"}
				</Button>
			</div>
		);
	};

	renderHeader = () => {
		const { t, store } = this.injected;
		const { start, end, period, filtersActive, sendEmailActive } =
			this.state;
		const r = store.restaurant!;

		const startToday = moment
			.tz(start, r.settings.region.timezone)
			.isSame(Date.now(), "day");
		const endToday = moment
			.tz(end, r.settings.region.timezone)
			.isSame(Date.now(), "day");
		const isToday = startToday && endToday;
		const displayStart = t("dateFriendlyFromTimestamp", { value: start });
		const displayEnd = t("dateFriendlyFromTimestamp", { value: end });

		let displayRange = `${displayStart} - ${displayEnd}`;
		if (isToday) {
			displayRange = "Today";
		} else if (period === "day") {
			displayRange = displayStart;
		}

		return (
			<div className="flex-l-r-center m-b-6">
				<h1>Dashboard</h1>
				<div className="flex-line centered">
					<div className="text-right m-r-3">
						<p className="font-semi-bold m-b-1">
							Viewing Reports For
						</p>
						<p className="big">{displayRange}</p>
					</div>

					<DropDown
						active={filtersActive}
						open={() => this.setState({ filtersActive: true })}
						close={() => this.setState({ filtersActive: false })}
						width={420}
						offset={15}
						flow="left"
						content={this.dropdownFilterContent(period)}
					>
						<Button
							className="p-lr-2"
							size="xxs"
							color="primary-inverse"
						>
							<FaChevronDown />
						</Button>
					</DropDown>

					<DropDown
						active={sendEmailActive}
						open={() => this.setState({ sendEmailActive: true })}
						close={() => this.setState({ sendEmailActive: false })}
						width={420}
						offset={15}
						flow="left"
						content={this.renderEmailSelect()}
					>
						<CwTooltip
							text={
								<p className="lhp p-1">Email Overview Report</p>
							}
							width={70}
							position="bottom"
						>
							<Button
								className="p-lr-2 m-l-2"
								size="xxs"
								color="primary-inverse"
							>
								<AiOutlineMail />
							</Button>
						</CwTooltip>
					</DropDown>
				</div>
			</div>
		);
	};

	renderTotals = () => {
		const { data, error } = this.state;
		const { t } = this.injected;
		if (!data || data.outcome === 1 || error) {
			return null;
		}
		return (
			<div className="m-t-6 grid-2 md sm-gap">
				<Box shadow="one" className="col no-border">
					<div className="p-3 flex-l-r-center">
						<p className="big font-semi-bold">Total Sales</p>
						<p className="big">
							{t("currency", { value: data.sales_total })}
						</p>
					</div>
				</Box>
				<Box shadow="one" className="col no-border">
					<div className="p-3 flex-l-r-center">
						<p className="big font-semi-bold">Total Orders</p>
						<p className="big">
							{t("number", { value: data.orders_total })}
						</p>
					</div>
				</Box>
				<Box shadow="one" className="col no-border">
					<div className="p-3 flex-l-r-center">
						<p className="big font-semi-bold">Total Tips</p>
						<p className="big">
							{t("currency", { value: data.tips_total })}
						</p>
					</div>
				</Box>
				<Box shadow="one" className="col no-border">
					<div className="p-3 flex-l-r-center">
						<p className="big font-semi-bold">Number of Tips</p>
						<p className="big">
							{t("number", { value: data.tips_count })}
						</p>
					</div>
				</Box>
			</div>
		);
	};

	renderChart = () => {
		const { data, error, chart } = this.state;
		const { theme, t, store } = this.injected;
		if (!data || data.outcome === 1 || error) {
			return null;
		}

		const currencySymbol = store.restaurant?.settings.region.currency.symbol || "$";

		const isSales = chart === "sales";

		const chartData =
			!data || data.outcome !== 0
				? []
				: (isSales ? data.sales_breakdown : data.orders_breakdown).map(
						({ l, v }) => ({ name: l, value: v })
				  );

		return (
			<div className="m-t-6">
				<ButtonGroup
					size={"xxs"}
					selected={chart}
					buttonClassName="no-round-bottom"
					onSelect={(s) =>
						this.setState({ chart: s.value as "sales" | "orders" })
					}
					width={60}
					options={[
						{ name: "Sales", value: "sales" },
						{ name: "Orders", value: "orders" },
					]}
				/>

				<Box
					shadow="one"
					style={{ height: "400px" }}
					className="no-border no-round-left"
				>
					<ResponsiveContainer width="100%" height="100%">
						<LineChart
							data={chartData}
							margin={{
								top: 50,
								right: 50,
								bottom: 30,
								left: 15,
							}}
						>
							<CartesianGrid stroke="#ccc" />

							<XAxis dataKey="name" minTickGap={4} interval={0}>
								<Label position="bottom" offset={5}>
									Time
								</Label>
							</XAxis>

							<YAxis
								dataKey="value"
								label={{
									value: isSales ? `Amount (${currencySymbol})` : "Orders",
									angle: -90,
									position: "insideLeft",
								}}
							/>

							<Line
								type="monotone"
								dataKey="value"
								stroke={theme.colors.primary}
								animationDuration={600}
								fontWeight="bold"
								strokeWidth={2}
							>
								<LabelList
									dataKey="value"
									position="top"
									offset={10}
									content={({ x, y, stroke, value }) =>
										value === 0
											? ""
											: isSales
											? t("currency", { value })
											: t("number", { value })
									}
								/>
							</Line>

							<Tooltip
								formatter={(value) =>
									isSales
										? t("currency", { value })
										: t("number", { value })
								}
							/>
						</LineChart>
					</ResponsiveContainer>
				</Box>
			</div>
		);
	};

	renderDetails = () => {
		const { data, error } = this.state;
		const { t, store } = this.injected;
		if (!data || data.outcome === 1 || error) {
			return null;
		}

		const r = store.restaurant!;

		const details = [
			{
				name: "Payment Methods",
				data: Object.keys(data.payment_counts).map((k) => {
					const m = r.settings.payments[k];
					const c = data.payment_counts[k];
					const s = data.payment_sales[k];
					const tip = data.payment_tips[k];
					//   const tip = data.order_tips[k];
					if (!m) return null;
					const name = store.getPaymentMethodName(k);
					return {
						l: name,
						v: `${t("currency", {
							value: s,
						})} | ${c} | ${t("currency", {
							value: tip.toFixed(2),
						})}`,
						h: !m.enabled && c === 0,
					};
				}),
			},
			{
				name: "Order Types",
				data: Object.keys(data.service_counts).map((key) => {
					const k =
						key as T.Schema.Restaurant.Services.RestaurantServiceTypes;
					const c = data.service_counts[k];
					const s = data.service_sales[k];
					const tip = data.service_tips[k];
					return {
						l: t(`constants.services.${key}`),
						v:
							k === "table_booking"
								? c
								: `${t("currency", {
										value: s,
								  })} | ${c} | ${t("currency", {
									value: tip.toFixed(2),
								})}`,
						h: !r.settings.services[k].enabled && c === 0,
					};
				}),
			},
			{
				name: "Order Completion",
				data: [
					{
						l: "Complete",
						v: `${t("currency", {
							value: data.status_sales.complete,
						})} | ${
							data.status_counts.complete
						} | ${t("currency", {
							value: data.status_tips.complete.toFixed(2),
					  })}`,
					},
					{
						l: "In-Complete",
						v: `${t("currency", {
							value: data.status_sales.incomplete,
						})} | ${
							data.status_counts.incomplete
						} | ${t("currency", {
							value: data.status_tips.incomplete.toFixed(2),
					  })}`,
					},
					{
						l: "Cancelled",
						v: `${t("currency", {
							value: data.status_sales.cancelled,
						})} | ${
							data.status_counts.cancelled
						} | ${t("currency", {
							value: data.status_tips.cancelled.toFixed(2),
						})}`,
					},
				],
			},
		];

		return (
			<div>
				<div className="m-t-6 grid-3 lg sm-gap">
					{details.map((d, i) => (
						<Box key={i} shadow="one" className="col no-border">
							<BoxHeading className="p-tb-2 p-lr-3 flex-l-r-center">
								<p className="font-semi-bold">{d.name}</p>
								<p className="small">Sales | Count | Tips</p>
							</BoxHeading>
							<BoxSection className="p-3">
								<DetailRowList
									showNull={true}
									spacing={6}
									items={d.data}
								/>
							</BoxSection>
						</Box>
					))}
				</div>
			</div>
		);
	};

	renderCounts = () => {
		const { data, error, counts } = this.state;
		const { store } = this.injected;
		const availableCategoryCounts: T.API.DashboardReportsBasicResponseCounts[] =
			[];
		const deletedCategoryCounts: T.API.DashboardReportsBasicResponseCounts[] =
			[];
		const dishCounts: T.API.DashboardReportsBasicResponseCounts[] = [];

		if (!data || data.outcome === 1 || error) {
			return null;
		}

		const r = store.restaurant!;

		function sortItems(
			a: T.API.DashboardReportsBasicResponseCounts,
			b: T.API.DashboardReportsBasicResponseCounts
		) {
			// Use toUpperCase() to ignore character casing
			const qtyA = a.qty;
			const qtyB = b.qty;

			let comparison = 0;
			if (qtyA < qtyB) {
				comparison = 1;
			} else if (qtyA > qtyB) {
				comparison = -1;
			}
			return comparison;
		}

		// get the Category Name based on Category Id get from API
		if (data.category_counts) {
			data.category_counts.map((categoryCount) => {
				const category = RestaurantUtils.menu.findCategoryById(
					r,
					categoryCount._id
				);
				if (category) {
					availableCategoryCounts.push({
						name:
							category.category.display_name ||
							category.category.name,
						qty: categoryCount.qty,
					});
				} else
					deletedCategoryCounts.push({
						name: `Deleted Category - ${categoryCount._id}`,
						qty: categoryCount.qty,
					});
			});
		}

		if (data.dish_counts) {
			data.dish_counts.map((dishCount) => {
				dishCounts.push({ name: dishCount.name, qty: dishCount.qty });
			});
		}

		const countsData: T.API.DashboardReportsBasicResponseCounts[] =
			counts === "item"
				? dishCounts.sort(sortItems)
				: counts === "category"
				? [
						...availableCategoryCounts.sort(sortItems),
						...deletedCategoryCounts.sort(sortItems),
				  ]
				: data.tax_data;

		return (
			<div className="m-t-6">
				<ButtonGroup
					size={"xxs"}
					selected={counts}
					buttonClassName="no-round-bottom"
					onSelect={(s) =>
						this.setState({
							counts: s.value as "item" | "category" | "tax",
						})
					}
					width={120}
					options={[
						{ name: "Item Counts", value: "item" },
						{ name: "Category Counts", value: "category" },
						{ name: "Tax", value: "tax" },
					]}
				/>

				<Box shadow="one" className="col no-border">
					<BoxHeading className="p-t-4 p-b-2 p-lr-3 flex-l-r-center">
						<p className="font-semi-bold">
							{_capitalize(counts)} Counts
						</p>
						{counts !== "tax" && <p className="small">Count</p>}
					</BoxHeading>
					<BoxSection>
						{counts !== "tax" &&
							countsData.map((item, index) => {
								return (
									<div
										key={index}
										className=""
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											fontSize: "14px",
											lineHeight: "1.3",
											marginBottom: "6px",
											paddingBottom: "6px",
											borderBottom:
												index === countsData.length - 1
													? ""
													: "1px dashed #d6d6d6",
										}}
									>
										<div className="fa-pull-left">
											{item.name}
										</div>
										<div className="fa-pull-right">
											{item.qty}
										</div>
									</div>
								);
							})}
						{counts === "tax" && data.tax_totals.length > 0 && (
							<div
								key={99999}
								className=""
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "left",
									fontSize: "14px",
									lineHeight: "1.3",
									marginBottom: "6px",
									paddingBottom: "6px",
									borderBottom: "1px dashed #d6d6d6",
								}}
							>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Tax Name
								</p>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Tax Rate
								</p>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Total Tax
								</p>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Total Taxable Revenue
								</p>
							</div>
						)}

						{counts === "tax" &&
							data.tax_totals.map((item: any, index: any) => {
								return (
									<div
										key={index}
										className=""
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "left",
											fontSize: "14px",
											lineHeight: "1.3",
											marginBottom: "6px",
											paddingBottom: "6px",
											borderBottom:
												index === countsData.length - 1
													? ""
													: "1px dashed #d6d6d6",
										}}
									>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["name"]}
										</div>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["rate"]}
										</div>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["amount"]}
										</div>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{(
												item["amount"] /
												(item["rate"] / 100)
											).toFixed(2)}
										</div>
									</div>
								);
							})}

						{counts === "tax" && data.tax_data.length > 0 && (
							<div
								key={99999999}
								className=""
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "left",
									fontSize: "14px",
									lineHeight: "1.3",
									marginBottom: "6px",
									paddingBottom: "6px",
									paddingTop: "50px",
									borderBottom: "1px dashed #d6d6d6",
								}}
							>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Order No
								</p>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Date
								</p>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Order Total
								</p>
								<p
									style={{ width: "17%" }}
									className="font-semi-bold"
								>
									Tax Name
								</p>
								<p
									style={{ width: "16%" }}
									className="font-semi-bold"
								>
									Tax Rate
								</p>
								<p
									style={{ width: "16%" }}
									className="font-semi-bold"
								>
									Tax Total
								</p>
							</div>
						)}
						{counts === "tax" &&
							data.tax_data.map((item: any, index: any) => {
								return (
									<div
										key={index}
										className=""
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "left",
											fontSize: "14px",
											lineHeight: "1.3",
											marginBottom: "6px",
											paddingBottom: "6px",
											borderBottom:
												index === countsData.length - 1
													? ""
													: "1px dashed #d6d6d6",
										}}
									>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["Order No"]}
										</div>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["Date"]}
										</div>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["Order Total"]}
										</div>
										<div
											style={{ width: "17%" }}
											className="fa-pull-left"
										>
											{item["Tax Name"]}
										</div>
										<div
											style={{ width: "16%" }}
											className="fa-pull-left"
										>
											{item["Tax Rate"]}
										</div>
										<div
											style={{ width: "16%" }}
											className="fa-pull-left"
										>
											{item["Tax Total"]}
										</div>
									</div>
								);
							})}
					</BoxSection>
				</Box>
			</div>
		);
	};

	render() {
		if (!this.state) return;
		const { data, error } = this.state;
		return (
			<Content width="lg">
				{this.renderHeader()}

				{!data && error && (
					<div className="m-t-10 max500 center text-center">
						<p className="error-bg p-2 round">
							Something went wrong loading your data, try again
							shortly or contact us
						</p>
						<Button
							className="m-t-4"
							onClick={this.query}
							color="primary-inverse"
							size="xs"
						>
							Try Again
						</Button>
					</div>
				)}

				{!data && !error && (
					<div className="flex-center height200">
						<RotateLoader />
					</div>
				)}

				{data && data.outcome === 0 && !error && (
					<div>
						{this.renderTotals()}
						{this.renderChart()}
						{this.renderDetails()}
						{this.renderCounts()}
					</div>
				)}
			</Content>
		);
	}
}

// @ts-ignore
export const RestaurantDashboard = withTheme(withTranslation()(RestaurantDashboardClass));
