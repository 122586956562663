import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Protable } from "@lib/components";
import { config } from "../../../../../config";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantUtils } from "@lib/common";
import { IconCircle } from "@lib/components";
import { RestaurantBookingModal } from "./modal";
import { RelativeTime } from "@lib/components";
import { OrderIcon } from "@lib/components";

interface Props extends WithTranslation { }
interface State {
	loading: boolean;
	item: T.Schema.Booking.BookingSchema | null;
}

@inject("store") @observer
class RestaurantBookingsClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			loading: true,
			item: null,
		};
	}

	render() {
		const { store, t } = this.injected;
		const r = store.restaurant!;
		const rid = r._id;
		const items = store.bookings;
		return (
			<div>

				<h1 className="m-b-6">Bookings</h1>

				<Protable<T.Schema.Booking.BookingSchema>
					region={r.settings.region}
					items={items.items}
					count={items.count}
					exportUrl={`${config.urls.api}/exports/dashboard/bookings?jwt=${store.auth.token}&rid=${store.restaurant!._id}`}
					screenWidth={store.view.screen_width}
					query={{
						sortKeys: [
							{ label: "Created", value: "created" },
							{ label: "Booking Date", value: "order.timestamp" },
							{ label: "Name", value: "customer.name" },
						],
						filters: [
							{
								label: "Status",
								key: "status",
								values: [
									{ label: "Un-Confirmed", value: "unconfirmed" },
									{ label: "Confirmed", value: "confirmed" },
									{ label: "Cancelled", value: "cancelled" },
								],
							},
						],
					}}
					onClick={(item) => {
						store.router.push(`/restaurant/${rid}/bookings?_id=${item._id}`);
						this.setState({ item });
						store.notifications.mark_read_object("booking", item._id);
					}}
					fetch={async (data) => {
						data.query.restaurant_id = store.restaurant!._id;
						const response = await store.api.bookings_find(data);
						if (response.outcome)
							throw new Error(response.message);
						store.updateBookings({
							items: response.items,
							count: response.count,
							page: data.page,
						});
					}}
					rowAlert={(order) => order.status === "unconfirmed"}
					columns={[
						{
							title: "",
							width: 80,
							render: (row) => {
								return (
									<IconCircle
										className="center"
										size={38}
										icon={<OrderIcon service="table_booking" />}
										background={RestaurantUtils.order.color(row.status)}
									/>
								);
							},
						},
						{
							title: "# / Name",
							render: (row) => (
								<div>
									<p className="big font-semi-bold m-b-1">{row.number}</p>
									<p>{row.customer.name}</p>
								</div>
							),
						},
						{
							title: "Phone",
							breakpoint: 1000,
							render: (row) => <span>{row.customer.phone}</span>,
						},
						{
							title: "Email",
							breakpoint: 1100,
							render: (row) => <span>{row.customer.email}</span>,
						},
						{
							title: "Booking For",
							breakpoint: 520,
							render: (row) => {
								return <span>{t("datetimeFromTimestamp", { value: row.config.timestamp })}</span>;
							},
						},
						{
							title: "Placed",
							breakpoint: 620,
							render: (row) => <RelativeTime timestamp={row.created} />,
						},
						{
							title: "No. Of People",
							breakpoint: 720,
							render: (row) => <span>{t("number", { value: row.config.number_of_people })}</span>,
						},
						{
							title: "Status",
							breakpoint: 820,
							render: (row) => (
								<span>{t(`order.status.${row.status}`)}</span>
							),
						},
					]}
				/>

				<RestaurantBookingModal />

			</div>
		);
	}

}

export const RestaurantBookings = withTranslation()(RestaurantBookingsClass);
