import UrlPattern from "url-pattern";
import { Routes, RouteMatch, RouteKeys } from "../types/routes.d";

export const routes: Routes = {

	home: {
		area: "main",
		pattern: new UrlPattern("/"),
		title: "Dashboard",
		auth: true,
	},
	website: {
		area: "main",
		pattern: new UrlPattern("/website"),
		title: "Website",
		auth: true,
		restriction_keys: ["website.create", "website.edit"],
	},
	staff: {
		area: "main",
		pattern: new UrlPattern("/staff"),
		title: "Staff",
		auth: true,
		restriction_keys: ["staff.create", "staff.edit", "staff.delete"],
	},
	developer: {
		area: "main",
		pattern: new UrlPattern("/developer"),
		title: "Developer Access",
		auth: true,
		restriction_keys: ["api.create", "api.edit", "api.delete"],
	},
	restaurant_dashboard: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)"),
		title: "Restaurant Dashboard",
		auth: true,
		restriction_keys: ["restaurant.dashboard"],
	},
	restaurant_orders: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)/orders"),
		title: "Orders",
		auth: true,
		restriction_keys: [
			"restaurant.orders",
			"restaurant.orders_board",
			"restaurant.orders_list",
		],
	},
	restaurant_bookings: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)/bookings"),
		title: "Bookings",
		auth: true,
		restriction_keys: ["restaurant.bookings"],
	},
	restaurant_customers: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)/customers"),
		title: "Customers",
		auth: true,
		restriction_keys: ["restaurant.customers"],
	},
	restaurant_menus: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)/menus"),
		title: "Menus",
		auth: true,
		restriction_keys: ["restaurant.menus"],
	},
	restaurant_settings: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)/settings"),
		title: "Settings",
		auth: true,
		restriction_keys: [
			"restaurant.settings",
			"restaurant.settings_detail.system",
			"restaurant.settings_detail.services",
			"restaurant.settings_detail.payments",
			"restaurant.settings_detail.website",
			"restaurant.settings_detail.integrations",
		],
	},
	restaurant_stripe_connect: {
		area: "restaurant",
		pattern: new UrlPattern("/restaurant/(:rid)/online_payments"),
		title: "Online Payments",
		auth: true,
		restriction_keys: [
			"online_payment.view_transaction"
		],
	},

	sso: {
		area: "auth",
		pattern: new UrlPattern("/sso"),
		title: "SSO",
		auth: true,
	},
	login: {
		area: "auth",
		pattern: new UrlPattern("/login"),
		title: "Login",
		auth: false,
	},
	register: {
		area: "auth",
		pattern: new UrlPattern("/register"),
		title: "Register",
		auth: false,
	},
	password_reset_init: {
		area: "auth",
		pattern: new UrlPattern("/reset-password"),
		title: "Reset Password",
		auth: false,
	},
	password_reset_change: {
		area: "auth",
		pattern: new UrlPattern("/reset-password-confirm"),
		title: "Reset Password",
		auth: false,
	},

};

export const cleanURLForMatch = (url: string) => url.split("?")[0];

export const guestRoutes = (() => {
	const guest_routes: UrlPattern[] = [];
	for (const route_key in routes) {
		if (routes.hasOwnProperty(route_key)) {
			const key = route_key as RouteKeys;
			if (!routes[key].auth) {
				guest_routes.push(routes[key].pattern);
			}
		}
	}
	return guest_routes;
})();

export const routePathIsGuest = (url: string) => {
	url = cleanURLForMatch(url);
	for (const route_key in routes) {
		if (routes.hasOwnProperty(route_key)) {
			const key = route_key as RouteKeys;
			if (!routes[key].auth) {
				const route = routes[key];
				const isMatch = route.pattern.match(url);
				if (isMatch) {
					return { ...route, match: true };
				}
			}
		}
	}
	return null;
};

export const routePathIsAuth = (url: string) => {
	url = cleanURLForMatch(url);
	for (const route_key in routes) {
		if (routes.hasOwnProperty(route_key)) {
			const key = route_key as RouteKeys;
			if (routes[key].auth) {
				const route = routes[key];
				const isMatch = route.pattern.match(url);
				if (isMatch) {
					return { ...route, match: true };
				}
			}
		}
	}
	return null;
};

export const routeIsMatch = (url: string): RouteMatch | null => {
	url = cleanURLForMatch(url);
	for (const route_key in routes) {
		if (routes.hasOwnProperty(route_key)) {
			const key = route_key as RouteKeys;
			const route = routes[key];
			const isMatch = route.pattern.match(url);
			if (isMatch) {
				return { ...route, match: isMatch, key: key };
			}
		}
	}
	return null;
};

export const routeMatch = (url: string) => {
	url = cleanURLForMatch(url);
	for (const route_key in routes) {
		if (routes.hasOwnProperty(route_key)) {
			const key = route_key as RouteKeys;
			const route = routes[key];
			const isMatch = route.pattern.match(url);
			if (isMatch) {
				return isMatch as { [key: string]: string | null };
			}
		}
	}
	return null;
};
