import { GoogleServiceLib } from "@lib/common";
import { config } from "../../config";

export const GoogleCreateService = GoogleServiceLib(config.services.google.api_key);

// export const GoogleCreateService = (originalApiKey: any, reseller: any) => {
// 	//
// 	console.log(originalApiKey)
// 	console.log(reseller.billing.reseller_billing_google_api_key)
// 	let GoogleService;
// 	//
// 	if (reseller && reseller.billing && reseller.billing.reseller_billing_google_api_key && reseller.billing.reseller_billing_google_api_key.length > 0) {
// 		//
// 		console.log("Google Maps API: Reseller Key")
// 		//
// 		try {
// 			//
// 			GoogleService = GoogleServiceLib(reseller.billing.reseller_billing_google_api_key)
// 			GoogleService.fonts.list();
// 			//
// 		} catch (e) {
// 			//
// 			console.log({ e })
// 			//
// 			console.log("Google Maps API: Invalid Reseller Key")
// 			GoogleService = null
// 			//
// 		}
// 		//
// 	} else {
// 		//
// 		console.log("Google Maps API: Original Key")
// 		//
// 		try {
// 			//
// 			GoogleService = GoogleServiceLib(originalApiKey)
// 			GoogleService.fonts.list();
// 			//
// 		} catch (e) {
// 			//
// 			console.log("Google Maps API: Invalid Original Key")
// 			GoogleService = null
// 			//
// 		}
// 		//
// 	}
// 	//
// 	return GoogleService;
// 	//
// }

