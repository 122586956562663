import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, TranslationProps } from "react-i18next";
import shortid from "shortid";
import difference from "lodash/difference";

import {
	FaCheck,
	FaTimes,
	FaPlus,
	FaArrowCircleUp,
	FaArrowCircleDown,
	FaTrashAlt,
} from "react-icons/fa";

import {
	RestaurantUtils,
	PaymentMethods,
	ThreeDsPaymentMethods,
	cloneDeepSafe,
	arraySwapItem,
	logger,
	defaultCardConnectSettings,
	Untrusive,
} from "@lib/common";

import {
	IconCircle,
	styled,
	SecureIcon,
	Button,
	Tooltip,
	Modal,
	ModalContent,
	ModalTitle,
	ListSelect,
} from "@lib/components";

import { SettingsSection } from "../layout/section";
import { SettingsSectionHeading } from "../layout/heading";
import { SettingsSectionBlock } from "../layout/block";
import { MobxComponent } from "../../../../../../mobx/components";

import { SettingsFormPaymentsCash } from "../forms/payments/cash";
import { SettingsFormPaymentsCard } from "../forms/payments/card";
import { SettingsFormPaymentsStripe } from "../forms/payments/stripe";
import { SettingsFormPaymentsPayPal } from "../forms/payments/paypal";
import { SettingsFormPaymentsCardConnect } from "../forms/payments/cardconnect";
import { SettingsFormPaymentsPoliPay } from "../forms/payments/polipay";
import { SettingsFormPaymentsPaygate } from "../forms/payments/paygate";
import { SettingsFormPaymentsIpay88 } from "../forms/payments/ipay88";
import { SettingsFormPaymentsBamboraAPAC } from "../forms/payments/bambora_apac";
import { SettingsFormPaymentsPesapal } from "../forms/payments/pesapal";
import { SettingsFormPaymentsCustom } from "../forms/payments/custom";
import { SettingsFormPaymentsAuthorized } from "../forms/payments/authorized";
import { SettingsFormPaymentsATHMovil } from "../forms/payments/ath_movil";
import { SettingsFormPaymentsCheckout } from "../forms/payments/checkout";
import { SettingsFormPaymentsGravity } from "../forms/payments/gravity";
import { SettingsFormPaymentsBamboraNa } from "../forms/payments/bambora_na";
import { SettingsFormPaymentsElavon } from "../forms/payments/elavon";
import { SettingsFormPaymentsCheckoutApplePay } from "../forms/payments/checkout_apple_pay";
import { SettingsFormPaymentsCheckoutGooglePay } from "../forms/payments/checkout_google_pay";
import { SettingsFormPaymentsStripeDigitalWallet } from "../forms/payments/stripe_digital_wallet";
import { SettingsFormPaymentsRedDot } from "../forms/payments/red_dot";
import { SettingsFormPaymentsRazorPay } from '../forms/payments/razor_pay';
import { SettingsFormPaymentsGkash } from '../forms/payments/gkash';
import { SettingsFormPaymentsSGE } from "../forms/payments/sgepay";

import { UI } from "../../../../../../core/ui";
import { SettingsFormPaymentsPayWay } from "../forms/payments/payway";

const IconWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  align-self: flex-end;
  margin-left: 0.5rem;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type AddPaymentType =
	| T.Schema.Restaurant.Payments.RestaurantPaymentTypesBase
	| "custom"
	| "";

interface Props extends TranslationProps { }
interface State {
	active: string;
	addPaymentModal: boolean;
	addPaymentType: AddPaymentType;
}

@inject("store")
@observer
class RestaurantSettingsPaymentsClass extends MobxComponent<Props, State> {
	reorderTimeout: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			active: "",
			addPaymentModal: false,
			addPaymentType: "",
		};
	}

	setActive = (active: string) => {
		if (this.state.active === active) this.setState({ active: "" });
		else this.setState({ active });
	};

	methodReorder = async (direction: "up" | "down", key: string) => {
		try {
			const { store } = this.injected;
			const { restaurant } = store;
			const r = cloneDeepSafe(restaurant)!;
			const settings = cloneDeepSafe(r.settings);
			const paymentKeys = Object.keys(settings.payments);
			const index = paymentKeys.indexOf(key);

			let swapIndex = -1;
			if (direction === "up" && index > 0) {
				swapIndex = index - 1;
			} else if (direction === "down" && index < paymentKeys.length - 1) {
				swapIndex = index + 1;
			}

			if (swapIndex === -1) {
				return;
			}

			clearTimeout(this.reorderTimeout);

			arraySwapItem(paymentKeys, index, swapIndex);

			const payments: {
				[key: string]:
				| T.Schema.Restaurant.Payments.RestaurantPayment
				| undefined;
			} = {};

			for (const pk of paymentKeys) {
				payments[pk] = settings.payments[pk];
			}

			settings.payments = payments;

			store.updateRestaurant({ settings });

			this.reorderTimeout = setTimeout(async () => {
				await this.saveRestaurantSilent({
					backup: r,
					update: { $set: { settings } },
					errorMsg:
						"Failed to save payment order, check connection and try again",
					before: () => { },
					onSuccess: () => { },
					onFail: () => { },
					onError: () => { },
				});
			}, 2000);
		} catch (e) {
			logger.captureException(e);
		}
	};

	methodRemove = async (key: string) => {
		const restaurant = this.injected.store.restaurant!;
		const methods = Object.keys(restaurant.settings.payments);

		if (methods.length === 1) {
			UI.notification.error("Cannot remove last payment method");
			return;
		}

		const msg =
			"Removing this payment method will permanently delete any data associated with it. You can disable it instead. Proceed with removal?";

		const proceed = confirm(msg);

		if (!proceed) {
			return;
		}

		Untrusive.start();

		await this.saveRestaurant({
			successMsg: "Payment method deleted",
			process: (r) => {
				delete r.settings.payments[key];
				return {
					update: {
						$set: {
							"settings.payments": r.settings.payments,
						},
					},
				};
			},
		});

		Untrusive.stop();
	};

	addPaymentMethod = async () => {
		const type = this.state.addPaymentType;

		let paymentData: any | undefined;
		if (type === "cash" || type === "card" || type === "custom") {
			paymentData = {
				enabled: false,
				services: [],
				label: type === "custom" ? "Custom Payment Method" : "",
			} as T.Schema.Restaurant.Payments.RestaurantPayment;
		} else if (type === "stripe") {
			paymentData = {
				enabled: false,
				label: "",
				services: [],
				currency: "",
				secret_key: "",
				publishable_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentStripe;
		} else if (type === "paypal") {
			paymentData = {
				enabled: false,
				label: "",
				services: [],
				currency: "",
				client_id: "",
				secret: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentPayPal;
		} else if (type === "cardconnect") {
			paymentData = defaultCardConnectSettings();
		} else if (type === "paygate_payweb") {
			paymentData = {
				enabled: false,
				label: "",
				services: [],
				currency: "",
				secret: "",
				paygate_id: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentPaygatePayweb;
		} else if (type === "poli_pay") {
			paymentData = {
				enabled: false,
				label: "",
				services: [],
				currency: "",
				merchant_id: "",
				auth_code: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentPoliPay;
		} else if (type === "ipay88") {
			paymentData = {
				enabled: false,
				label: "",
				services: [],
				currency: "",
				merchant_code: "",
				merchant_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentIpay88;
		} else if (type === "bambora_apac") {
			paymentData = {
				enabled: false,
				testing: false,
				label: "",
				services: [],
				currency: "",
				merchant_id: "",
				api_username: "",
				api_password: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentBamboraAPAC;
		} else if (type === "pesapal") {
			paymentData = {
				enabled: false,
				label: "Pesapal",
				services: [],
				testing: false,
				consumer_key: "",
				consumer_secret: "",
				currency: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentPesapal;
		} else if (type === "ath_movil") {
			paymentData = {
				enabled: false,
				env: "",
				label: "ATH Móvil",
				public_token: "",
				private_token: "",
				theme: "btn",
				lang_code: "",
				timeout: 0,
			} as T.Schema.Restaurant.Payments.RestaurantPaymentATHMovil;
		} else if (type === "checkout") {
			paymentData = {
				enabled: false,
				label: "Credit Card",
				services: [],
				currency: "",
				checkout_public_key: "",
				checkout_secret_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentCheckout;
		} else if (type === "checkout_apple_pay") {
			paymentData = {
				enabled: false,
				label: "Apple Pay (Checkout.com)",
				services: [],
				currency: "",
				checkout_public_key: "",
				checkout_secret_key: "",
				apple_pay_merchant_id: "",
				apple_pay_merchant_domain: "",
				apple_pay_merchant_identity_cert: "",
				apple_pay_merchant_identity_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutApplePay;
		} else if (type === "checkout_google_pay") {
			paymentData = {
				enabled: false,
				label: "Google Pay (Checkout.com)",
				services: [],
				currency: "",
				checkout_public_key: "",
				checkout_secret_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutGooglePay;
		}
		else if (type === "stripe_digital_wallet") {
			let stripedigitalpay_settings;
			const restaurant_id = this.injected.store.restaurant?._id!;
			if (restaurant_id) {
				const response = await this.injected.store.api.dashboard_restaurant_payment_prefill_stripedigitalpay({ restaurant_id });
				if (!response.outcome) {
					stripedigitalpay_settings = response.data as T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet
				}
			}
			if (stripedigitalpay_settings) {
				paymentData = stripedigitalpay_settings;
				paymentData.enabled = false;
				paymentData.label = "Apple Pay | Google Pay (Stripe)";
			} else {
				paymentData = {
					enabled: false,
					label: "Apple Pay | Google Pay (Stripe)",
					services: [],
					currency: "",
					stripe_public_key: "",
					stripe_secret_key: "",
					stripe_domain_association: ""
				} as T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet;
			}
		} else if (type === "razor_pay") {
			paymentData = {
				enabled: false,
				label: "Razor Pay",
				services: [],
				currency: "",
				razor_pay_public_key: "",
				razor_pay_secret_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentRazorPay;
		}
		//NEWPAYMENTTYPE
		else if (type === "gravity") {
			paymentData = {
				enabled: false,
				label: "Credit Card",
				services: [],
				// currency: "",
				gravity_oid: "",
				gravity_auth_key: "",
				gravity_environment:
					"https://api.emergepay.chargeitpro.com/virtualterminal/v1",
				gravity_assets: "https://assets.emergepay.chargeitpro.com",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentGravity;
		} else if (type === "authorized") {
			paymentData = {
				enabled: false,
				label: "Credit Card",
				services: [],
				// currency: "",
				authorized_login_id: "",
				authorized_transaction_id: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentAuthorized;
		} else if (type == "bambora_na") {
			paymentData = {
				enabled: false,
				label: "Bambora North America",
				services: [],
				merchant_id: "",
				passcode: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentBamboraNA;
		} else if (type === "elavon") {
			paymentData = {
				enabled: false,
				label: "Elavon",
				services: [],
				ssl_merchant_id: "",
				ssl_user_id: "",
				ssl_pin: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentElavon;
		} else if (type === "red_dot") {
			paymentData = {
				enabled: false,
				label: "Red Dot Payment",
				services: [],
				testing: false,
				merchant_id: "",
				secret_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentRedDot;
		} else if (type === 'gkash') {
			paymentData = {
				enabled: false,
				label: "Gkash",
				services: [],
				testing: false,
				merchant_id: "",
				signature_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentGkash;
		} else if (type === 'sgepay') {
			paymentData = {
				enabled: false,
				label: "Credit Card",
				services: [],
				testing: false,
				Mid: "",
				RequestId: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentSGE;
		} else if (type === 'payway') {
			paymentData = {
				enabled: false,
				label: "ABA Pay",
				services: [],
				testing: false,
				merchant_id: "",
				api_key: "",
			} as T.Schema.Restaurant.Payments.RestaurantPaymentPayway;
		}

		const key = type === "custom" ? shortid.generate() : type;

		this.setState({ addPaymentType: "", addPaymentModal: false });

		Untrusive.start();

		await this.saveRestaurant({
			successMsg: "Payment method created",
			errorMsg: "Failed to create payment method, try again or contact us",
			process: (r) => {
				r.settings.payments[key] = paymentData;
				return {
					update: {
						$set: {
							"settings.payments": r.settings.payments,
						},
					},
				};
			},
		});

		Untrusive.stop();
	};

	render() {
		const { active, addPaymentModal, addPaymentType } = this.state;
		const { store, t } = this.injected;
		const { theme, restaurant } = store;
		const { payments } = restaurant!.settings;

		const successIndicator = (
			<IconCircle
				size={16}
				className={"m-r-2"}
				icon={<FaCheck />}
				iconSizeModifier={6}
				background={theme.s.status_colors.complete}
			/>
		);
		const failIndicator = (
			<IconCircle
				size={16}
				className={"m-r-2"}
				icon={<FaTimes />}
				iconSizeModifier={6}
				background={"grey"}
			/>
		);

		const forms = {
			cash: <SettingsFormPaymentsCash />,
			card: <SettingsFormPaymentsCard />,
			stripe: <SettingsFormPaymentsStripe />,
			paypal: <SettingsFormPaymentsPayPal />,
			cardconnect: <SettingsFormPaymentsCardConnect />,
			paygate_payweb: <SettingsFormPaymentsPaygate />,
			poli_pay: <SettingsFormPaymentsPoliPay />,
			ipay88: <SettingsFormPaymentsIpay88 />,
			bambora_apac: <SettingsFormPaymentsBamboraAPAC />,
			pesapal: <SettingsFormPaymentsPesapal />,
			ath_movil: <SettingsFormPaymentsATHMovil />,
			checkout: <SettingsFormPaymentsCheckout />,
			checkout_apple_pay: <SettingsFormPaymentsCheckoutApplePay />,
			checkout_google_pay: <SettingsFormPaymentsCheckoutGooglePay />,
			stripe_digital_wallet: <SettingsFormPaymentsStripeDigitalWallet />,
			razor_pay: <SettingsFormPaymentsRazorPay />,
			gravity: <SettingsFormPaymentsGravity />,
			authorized: <SettingsFormPaymentsAuthorized />,
			bambora_na: <SettingsFormPaymentsBamboraNa />,
			elavon: <SettingsFormPaymentsElavon />,
			red_dot: <SettingsFormPaymentsRedDot />,
			gkash: <SettingsFormPaymentsGkash />,
			sgepay: <SettingsFormPaymentsSGE />,
			payway: <SettingsFormPaymentsPayWay />,
		};

		const validators = {
			cash: RestaurantUtils.settings.paymentActiveCash,
			card: RestaurantUtils.settings.paymentActiveCard,
			stripe: RestaurantUtils.settings.paymentActiveStripe,
			paypal: RestaurantUtils.settings.paymentActivePaypal,
			cardconnect: RestaurantUtils.settings.paymentActiveCardConnect,
			paygate_payweb: RestaurantUtils.settings.paymentActivePaygatePayweb,
			poli_pay: RestaurantUtils.settings.paymentActivePoliPay,
			ipay88: RestaurantUtils.settings.paymentActiveIpay88,
			bambora_apac: RestaurantUtils.settings.paymentActiveBamboraAPAC,
			bambora_na: RestaurantUtils.settings.paymentActiveBamboraNA,
			pesapal: RestaurantUtils.settings.paymentActivePesapal,
			ath_movil: RestaurantUtils.settings.paymentActiveATHMovil,
			checkout: RestaurantUtils.settings.paymentActiveCheckout,
			checkout_apple_pay: RestaurantUtils.settings.paymentActiveCheckoutApplePay,
			checkout_google_pay: RestaurantUtils.settings.paymentActiveCheckoutGooglePay,
			stripe_digital_wallet: RestaurantUtils.settings.paymentActiveStripeDigitalWallet,
			razor_pay: RestaurantUtils.settings.paymentActiveRazorPay,
			gravity: RestaurantUtils.settings.paymentActiveGravity,
			authorized: RestaurantUtils.settings.paymentActiveAuthorized,
			elavon: RestaurantUtils.settings.paymentActiveElavon,
			red_dot: RestaurantUtils.settings.paymentActiveRedDot,
			gkash: RestaurantUtils.settings.paymentActiveGkash,
			sgepay: RestaurantUtils.settings.paymentActiveSGE,
			payway: RestaurantUtils.settings.paymentActivePayWay,
		};

		const methods = Object.keys(payments).map((key) => key);
		const allMethods = difference([...PaymentMethods, "custom"], methods);

		const Title = (props: {
			methodKey: string;
			title: string;
			active: boolean;
			index: number;
		}) => (
			<div className="flex-l-r-center">
				<div className="flex-line centered">
					{props.active ? successIndicator : failIndicator}
					<span>{props.title}</span>

					{ThreeDsPaymentMethods.includes(props.methodKey) && (
						<IconWrapper>
							<SecureIcon />
						</IconWrapper>
					)}
				</div>
				<div className="flex-line centered m-r-4">
					<Tooltip text="Remove" width={65} position={"top"}>
						<Button
							size="xs"
							color="white"
							className="p-lr-1"
							onClick={(e) => {
								e.stopPropagation();
								this.methodRemove(props.methodKey);
							}}
						>
							<FaTrashAlt />
						</Button>
					</Tooltip>
					<Tooltip text="Move Up" width={70} position={"top"}>
						<Button
							size="xs"
							color="white"
							className="p-lr-1 m-l-1"
							onClick={(e) => {
								e.stopPropagation();
								this.methodReorder("up", props.methodKey);
							}}
						>
							<FaArrowCircleUp />
						</Button>
					</Tooltip>
					<Tooltip text="Move Down" width={90} position={"top"}>
						<Button
							size="xs"
							color="white"
							className="p-lr-1 m-l-1"
							onClick={(e) => {
								e.stopPropagation();
								this.methodReorder("down", props.methodKey);
							}}
						>
							<FaArrowCircleDown />
						</Button>
					</Tooltip>
				</div>
			</div>
		);

		return (
			<>
				<SettingsSection>
					<SettingsSectionHeading className="flex-l-r-center">
						<div />
						<div
							className="flex-line centered cursor"
							onClick={() =>
								this.setState({ addPaymentModal: true })
							}
						>
							<p className="underline">Add Payment Method</p>
							<p className="m-l-2">
								<FaPlus />
							</p>
						</div>
					</SettingsSectionHeading>
					{methods.map((key, i) => {
						const data = payments[key]!;
						const isBaseMethod = PaymentMethods.indexOf(key) !== -1;
						const name = isBaseMethod
							? t(`constants.payment.backend_method.${key}`)
							: data.label || "";
						const component = isBaseMethod ? (
							forms[key as keyof typeof forms]
						) : (
							<SettingsFormPaymentsCustom payment_id={key} />
						);
						const enabled = isBaseMethod
							? validators[key as keyof typeof forms](payments)
							: data.enabled;
						return (
							<SettingsSectionBlock
								key={i}
								name={
									<Title
										methodKey={key}
										title={name}
										active={enabled}
										index={i}
									/>
								}
								headerClass="p-tb-2"
								active={active === key}
								onClick={() => this.setActive(key)}
							>
								{component}
							</SettingsSectionBlock>
						);
					})}
				</SettingsSection>

				<Modal
					width={420}
					active={addPaymentModal}
					close={() =>
						this.setState({
							addPaymentModal: false,
							addPaymentType: "",
						})
					}
				>
					<ModalTitle className="round-top-sm">
						<h4>Add Payment Method</h4>
					</ModalTitle>
					<ModalContent>
						<p
							style={{
								lineHeight: "1.6rem",
								textIndent: "1rem",
							}}
						>
							Payment methods with shield icon are considered
							Strong Customer Authentication (SCA) readiness. If
							your business requires SCA, you may consider using
							those payment methods. Learn more about SCA{" "}
							<strong>
								<a
									href="https://stripe.com/guides/strong-customer-authentication#what-is-strong-customer-authentication"
									rel="noopener"
									target="_blank"
								>
									here
								</a>
							</strong>
							.
						</p>
					</ModalContent>
					<ModalContent>
						<ListSelect
							selected={addPaymentType}
							onChange={(value) =>
								this.setState({
									addPaymentType: value as AddPaymentType,
								})
							}
							items={allMethods.map((method) => ({
								value: method,
								render: () => (
									<Centered>
										<div className="width100 flex-line centered">
											{t(
												`constants.payment.backend_method.${method}`
											)}
										</div>
										{ThreeDsPaymentMethods.includes(
											method
										) && (
												<IconWrapper>
													<SecureIcon />
												</IconWrapper>
											)}
									</Centered>
								),
							}))}
						/>
					</ModalContent>
					{!!addPaymentType && (
						<ModalContent>
							<Button
								color="primary"
								full={true}
								onClick={this.addPaymentMethod}
							>
								Add Method
							</Button>
						</ModalContent>
					)}
				</Modal>
			</>
		);
	}
}

// @ts-ignore
export const RestaurantSettingsPayments = withTranslation()(RestaurantSettingsPaymentsClass);
