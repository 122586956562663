import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { UI } from "../../../../../../../core/ui";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { LinkTag } from "@lib/components";

interface Props {
	close?: () => void;
}
interface State { }

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"]["providers"];

@inject("store") @observer
export class SettingsFormIntegrationTookan extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.services.delivery.providers;
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.services.delivery.providers = values;
					const update = { $set: { "settings.services.delivery.providers": r.settings.services.delivery.providers } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => {
					if (this.props.close) {
						this.props.close();
					}
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FormGroup>
								<p className="font-semi-bold big m-b-2">Important Instructions</p>
								<p className="lhp">
									For Tookan to function correctly, please visit the "Wait Times & Auto Statuses" tab under your delivery settings and enter values for "time till confirm", "time till ready" and "time till on route". These will be used to calculate the pickup and delivery times for orders accurately.
								</p>
							</FormGroup>

							<FastField
								name="tookan.api_key"
								render={({ field }: any) => (
									<FormGroup
										title="Tookan V2 API Key"
										help="Your unique Tookan V2 API key. You can generate one in Tookan's dashboard 'API Keys' page">
										<Input type="text" {...field} value={field.value || ""} />
									</FormGroup>
								)}
							/>

							<FastField
								name="tookan.utc_offset"
								render={({ field }: any) => (
									<FormGroup
										title="Tookan Timezone UTC Offset"
										help={<span>Visit <LinkTag href={"https://timezones.tookanapp.com"} target="_blank">https://timezones.tookanapp.com/</LinkTag> to get your timezone UTC offset</span>}>
										<Input type="text" {...field} value={field.value || ""} />
									</FormGroup>
								)}
							/>

							{<FastField
								name="tookan.auto_book"
								render={({ field }: any) => (
									<FormGroup
										title="Auto Book Deliveries"
										help="When enabled, deliveries will be automatically booked with with Tookan when an order is placed. Otherwise you will need to manually book deliveries through the orders interface. If auto booking deliveries, we also recommend auto-confirming orders by enabling automated order statuses and setting the time till confirm 0">
										<Switch
											id="tookan-auto-book-switch"
											checked={field.value || false}
											onChange={(e) => setFieldValue("tookan.auto_book", e.target.checked)} />
									</FormGroup>
								)}
							/>}

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
