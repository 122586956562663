
const isProduction = process.env.NODE_ENV === "production";
const current_host = window.location.host;

export const config = {
	build: 1,
	isProduction: isProduction,
	isTest: !isProduction,
	host: current_host,
	urls: {
		api: isProduction 
			? process.env.API_HOST || "https://api.cloudwaitress-staging.xyz" 
			: "http://localhost:3010",
		// api: isProduction ? "https://api.cloudwaitress.com" : "https://api-dev.ap.ngrok.io",
		api2: isProduction 
			? process.env.API2_HOST || "https://api.cloudwaitress-staging.xyz" 
			: "http://localhost:3010",
		s3: "https://s3.amazonaws.com/ordering-platform",
		cloudfront: "https://dol98aud6tbh0.cloudfront.net",
	},
	services: {
		amplitude: {
			api_key: isProduction ? "dd8d0f25461f3da1628fa5ec95aa5bc5" : "b2fa8807128788a1a0b4e9b474883dc8",
		},
		api: {
			public_key: "c72d69de-f2df-45c3-9675-4fdb2308273c",
		},
		google: {
			api_key: "AIzaSyAc_6JJnBdHbKjXGeoKIznTE22-YaRRuSA",
		},
		mapbox: {
			api_key: "pk.eyJ1IjoicmVhbGZyZXNoIiwiYSI6ImNqb2hkeTdzbTBvczMzcXBqeGE0MXlwbjkifQ.L_3vQEe6bef12JJyt6268w",
		},
		stripe: {
			public_key: isProduction 
				? process.env.STRIPE_PUBLIC_KEY || "pk_test_qEboEw24dxDDWiAyDFUy886E" 
				: "pk_test_qEboEw24dxDDWiAyDFUy886E",
		},
	},
};
