import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["colors"];

@inject("store") @observer
export class SettingsFormColors extends MobxComponent<Props, State> {
	initialValues: FormValues;
	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.state = {};
		this.initialValues = r.website.colors;
	}
	render() {
		const col_props = {
			className: "col",
			small_title: true,
			no_border: true,
		};
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.website.colors = values;
					const update = { $set: { "website.colors": values } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">


							<FastField
								name="primary"
								render={({ field }: any) => (
									<FormGroup
										title="Primary" help="Used for all buttons, icons and other highlighted points. Ideally your main brand color">
										<ColorPicker
											color={field.value}  
											onChange={(color) => setFieldValue("primary", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="primary_text"
								render={({ field }: any) => (
									<FormGroup
										title="Primary Text" help="The color of any text or icons when used on top of your primary color. Ideally white or black">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("primary_text", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="background"
								render={({ field }: any) => (
									<FormGroup
										title="Background" help="This is the overall background color of the page and your menus">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("background", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="text"
								render={({ field }: any) => (
									<FormGroup
										title="Text" help="The color of any text used on your background">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("text", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="box"
								render={({ field }: any) => (
									<FormGroup
										title="Box & Popup Background" help="The color of any boxes or popup backgrounds. Includes your menu items">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("box", color)} />
									</FormGroup>
								)}
							/>

							<FastField
								name="box_text"
								render={({ field }: any) => (
									<FormGroup
										title="Box & Popup Text" help="The color of any text or icons in your boxes or popups. Includes menu items">
										<ColorPicker
											color={field.value}
											onChange={(color) => setFieldValue("box_text", color)} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
