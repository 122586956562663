import * as React from "react";
import { FastField } from "formik";
import { FormGroup, Switch } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { ColorPicker } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../../mobx/components";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { Input } from "@lib/components";
import { FormUpload } from "@lib/components";

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["top_nav"];

@inject("store") @observer
export class SettingsFormDesignTopNav extends MobxComponent<Props, State> {
	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.state = {};
		this.initialValues = r.website.sections.top_nav;
	}

	render() {
		const col_props = {
			className: "col",
			small_title: true,
			no_border: true,
		};
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {

					console.log({values})

					r.website.sections.top_nav = values;
					const update = { $set: { "website.sections.top_nav": values } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FormGroup
								title="Colors"
								contentClassName="grid-2 sm sm-gap max300">
								<FastField
									name="colors.background"
									render={({ field }: any) => (
										<FormGroup title="Background" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("colors.background", color)} />
										</FormGroup>
									)}
								/>
								<FastField
									name="colors.text"
									render={({ field }: any) => (
										<FormGroup title="Text" {...col_props}>
											<ColorPicker
												color={field.value}
												onChange={(color) => setFieldValue("colors.text", color)} />
										</FormGroup>
									)}
								/>
							</FormGroup>

							<FastField
								name="text.logo"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Logo Text"
										help="Text displayed in the top navigation of your store page. Defaults to the name of your restaurant. Use a single space to hide the logo text">
										<Input
											type="text"
											{...field}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="images.logo"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Logo Image"
										help="A logo image displayed on the top navigation of your store page. File name can't contain special charaters">
										<FormUpload
											maxSize={300}
											imagesOnly={true}
											value={[field.value]}
											customSource={true}
											onRemove={() => setFieldValue("images.logo", null)}
											onChange={(files) => setFieldValue("images.logo", files[0])} />
									</FormGroup>
								)}
							/>

							<FastField
								name="images.logo_link"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Logo Link"
										help="If a URL is entered, your logo image will link to this URL when clicked. Make sure to include http:// or https://">
										<Input
											type="url"
											value={field.value || ""}
											{...field}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="hide_menu_search_button"
								render={({ field }: any) => (
									<FormGroup
										title="Hide Menu Search Button"
										help="Use this option to hide menu search button on homepage of the restaurant.">
										<Switch
											id="hide_menu_search_button"
											checked={field.value || false}
											onChange={(e) => setFieldValue("hide_menu_search_button", e.target.checked)} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
