import * as React from "react";
import { FastField, Field } from "formik";
import { FormGroup, styled } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { Input } from "@lib/components";
import { Textarea } from "@lib/components";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { PaymentBaseFields } from "./base";
import _isEmpty from 'lodash/isEmpty';

interface Props { }
interface State { }
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutApplePay;

const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`

@inject("store") @observer
export class SettingsFormPaymentsCheckoutApplePay extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const r = this.injected.store.restaurant!;
		this.initialValues = r.settings.payments.checkout_apple_pay || {
			enabled: false,
			label: "",
			services: [],
			checkout_public_key: "",
			checkout_secret_key: "",
			apple_pay_merchant_id: '',
			apple_pay_merchant_domain: '',
			apple_pay_merchant_identity_cert: '',
			apple_pay_merchant_identity_key: '',
			apple_pay_merchant_domain_association: '',
		};
	}

	validateMerchantIdentityCertificate(value: string) {
		const regex = /^-{3,}BEGIN CERTIFICATE-{3,}$.*?^-{3,}END CERTIFICATE-{3,}$/gmsi;
		let error;
		if (!regex.test(value)) {
			error = 'Invalid Apple Pay merchant identity certificate provided.'
		}
		return error;
	}

	validateMerchantIdentityPrivateKey(value: string) {
		const regex = /^-{3,}BEGIN PRIVATE KEY-{3,}$.*?^-{3,}END PRIVATE KEY-{3,}$/gmsi;
		let error;
		if (!regex.test(value)) {
			error = 'Invalid Apple Pay merchant identity private key provided.'
		}
		return error;
	}

	validateRequiredField(value: string) {
		let error;
		if (_isEmpty(value)) {
			error = 'This field value cannot be empty.'
		}
		return error;
	}

	validateCheckoutPublicKey(value: string) {
		let error;
		if (_isEmpty(value) || !value.startsWith('pk_')) {
			error = 'Invalid Checkout.com public key provided.'
		}
		return error;
	}

	validateCheckoutSecretKey(value: string) {
		let error;
		if (_isEmpty(value) || !value.startsWith('sk_')) {
			error = 'Invalid Checkout.com secret key provided.'
		}
		return error;
	}

	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.payments.checkout_apple_pay = values;
					const update = { $set: { "settings.payments.checkout_apple_pay": r.settings.payments.checkout_apple_pay } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => {
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { errors, isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="enabled"
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Enabled"
											help="Allows customers to pay using this method">
											<Switch
												id="enable-switch"
												checked={field.value}
												onChange={(e) => setFieldValue("enabled", e.target.checked)} />
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="checkout_secret_key"
								validate={this.validateCheckoutSecretKey}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Checkout Secret Key"
											help="Your Checkout secret API key, do not to share this with anyone">
											<Input type="text" {...field} autoSave="false" autoCorrect="false" />
											{errors.checkout_secret_key && <ErrorBox>{errors.checkout_secret_key}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FastField
								name="checkout_public_key"
								validate={this.validateCheckoutPublicKey}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Checkout Public Key"
											help="Your Checkout public API key that will be used to initiate online charges">
											<Input type="text" {...field} autoSave="true" autoCorrect="false" />
											{errors.checkout_public_key && <ErrorBox>{errors.checkout_public_key}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<FormGroup>
								<div>
									<div style={{ marginBottom: "10px" }}>
										<p
											style={{
												fontSize: "16px",
												fontWeight: 600,
												color: "red",
												marginBottom: "10px",
											}}
										>
											Notes
										</p>
										<p style={{ fontSize: "14px", lineHeight: "18px" }}>
											To set up Apple Pay with Checkout.com, you need an Apple Developer Account, a valid domain and Checkout.com account.
											Please follow the guide <a href="https://support.cloudwaitress.com/how-to-guides/setup-apple-pay-with-checkout.com" target="_blank" rel="noopener"><strong>here</strong></a> to create
											essentials Apple Pay credentials.
										</p>
									</div>
								</div>
							</FormGroup>

							<Field
								name="apple_pay_merchant_id"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Apple Pay Merchant ID"
											help="The Apple Pay merchant identifier. You can find this value in Certificates, Identifiers & Profiles section of your Apple Developer Account">
											<Input type="text" {...field} autoSave="false" autoCorrect="false" />
											{errors.apple_pay_merchant_id && <ErrorBox>{errors.apple_pay_merchant_id}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<Field
								name="apple_pay_merchant_name"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Apple Pay Merchant Name"
											help="The Apple Pay merchant name. You can find this value in Certificates, Identifiers & Profiles section of your Apple Developer Account">
											<Input type="text" {...field} autoSave="false" autoCorrect="false" />
											{errors.apple_pay_merchant_name && <ErrorBox>{errors.apple_pay_merchant_name}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<Field
								name="apple_pay_merchant_domain"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Apple Pay Merchant Domain"
											help="The Apple Pay merchant domain. You can find this value in Certificates, Identifiers & Profiles section of your Apple Developer Account. The domain must be verified by Apple Pay.">
											<Input type="text" {...field} autoSave="false" autoCorrect="false" />
											{errors.apple_pay_merchant_domain && <ErrorBox>{errors.apple_pay_merchant_domain}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<Field
								name="apple_pay_merchant_domain_association"
								validate={this.validateRequiredField}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Apple Pay Merchant Domain Association"
											help="The content of apple-developer-merchantid-domain-association.txt that can be downloaded when adding the domain to the merchant identifier.">
											<Textarea {...field} autoSave="false" autoCorrect="false" />
											{errors.apple_pay_merchant_domain_association && <ErrorBox>{errors.apple_pay_merchant_domain_association}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<Field
								name="apple_pay_merchant_identity_cert"
								validate={this.validateMerchantIdentityCertificate}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Apple Pay Merchant Identity Certificate"
											help="The certificate should begin with '-----BEGIN CERTIFICATE-----' and end with '-----END CERTIFICATE-----'">
											<Textarea {...field} autoSave="false" autoCorrect="false" />
											{errors.apple_pay_merchant_identity_cert && <ErrorBox>{errors.apple_pay_merchant_identity_cert}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<Field
								name="apple_pay_merchant_identity_key"
								validate={this.validateMerchantIdentityPrivateKey}
								render={({ field }: any) => (
									<React.Fragment>
										<FormGroup
											title="Apple Pay Merchant Identity Private Key"
											help="The private key should begin with '-----BEGIN PRIVATE KEY-----' and end with '-----END PRIVATE KEY-----'">
											<Textarea {...field} autoSave="false" autoCorrect="false" />
											{errors.apple_pay_merchant_identity_key && <ErrorBox>{errors.apple_pay_merchant_identity_key}</ErrorBox>}
										</FormGroup>
									</React.Fragment>
								)}
							/>

							<PaymentBaseFields
								defaultLabel="Apple Pay (Checkout.com)"
								setFieldValue={setFieldValue}
							/>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}
}
