import React from 'react';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import { logger, Untrusive } from '@lib/common';
import { UI } from "../../../../../../core/ui";
import { Modal, ModalContent, ModalTitle, Select, Input, FormGroup, Button, RotateLoader } from '@lib/components';
import { MobxComponent } from '../../../../../../mobx/components';

interface State {
	submitting: boolean;
	reason: string;
}

interface Props extends WithTranslation {
	order: T.Schema.Order.OrderSchema,
	restaurant: T.Schema.Restaurant.RestaurantSchema,
	showModal: boolean;
	onClose: () => void;
}

const REFUND_REASONS = [
	{ label: '', value: '' },
	{ label: 'Duplicate', value: 'duplicate' },
	{ label: 'Fraudulent', value: 'fraudulent' },
	{ label: 'Requested By Customer', value: 'requested_by_customer' },
];

@inject("store") @observer
class StripeConnectRefundModalClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			submitting: false,
			reason: '',
		}
	}

	handleModalClose = () => {
		this.props.onClose();
	}

	handleReasonChange = (e: any) => {
		this.setState({ reason: e.target.value });
	}

	performRefund = async (e: any) => {
		e.preventDefault();

		const { store } = this.injected;
		const { reason } = this.state;
		const { order: { _id: order_id }, restaurant: { _id: restaurant_id } } = this.props;

		if (_isEmpty(reason)) {
			UI.notification.error('Refund reason cannot be empty');
			return;
		}

		try {
			const proceed = confirm('Once refunded, it cannot be reversed. Refund the order?');
			if (!proceed) { return; }
			Untrusive.start();
			const payload: T.API.DashboardOrderStripeConnectRefundRequest = {
				order_id,
				restaurant_id,
				refund_reason: reason,
			}

			const response = await store.api.order_stripe_connect_refund(payload);
			if (response.outcome) {
				UI.notification.error(response.message, { timeout: 6000 });
			}
			else {
				store.updateOrderComplete(response.order);
				UI.notification.success('Payment refunded');
			}
		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
		}
		finally {
			Untrusive.stop();
		}
	}

	render() {
		const { t } = this.injected;
		const showModal = this.props.showModal;
		const { submitting, reason } = this.state;
		const { payment: { currency, total_cents } } = this.props.order;
		const divider = currency !== 'JPY' ? 100 : 1;
		const amount = total_cents / divider;
		const displayingAmount = t("currency", { value: amount });
		const { order } = this.props;
		const refundToConnectedAccount = order.payment.stripe_connect_refunded_to_connected_account;
		const refundToCustomer = order.payment.stripe_connect_refunded_to_customer;
		const isRefundable: boolean = !refundToConnectedAccount && !refundToCustomer;

		return (
			<Modal
				width="xs"
				active={showModal}
				preventClose
				id="stripe-connect-refund-modal"
				close={this.handleModalClose}>
				<ModalTitle className="round-top">
					<h4>Stripe Connect Refund</h4>
				</ModalTitle>

				<ModalContent>
					<FormGroup>You are refunding a charge for {displayingAmount} {currency}.</FormGroup>

					<FormGroup title="Please select refund reason (required)">
						<Select
							className="no-round"
							options={REFUND_REASONS}
							style={{ width: "100%" }}
							value={reason}
							onChange={this.handleReasonChange}
							required={true} />
					</FormGroup>

					<FormGroup title={`Refund Amount (${currency})`}>
						<Input value={displayingAmount} readOnly />
					</FormGroup>

					<FormGroup>
						<Button
							full={true}
							color="primary"
							type="button"
							disabled={submitting || !isRefundable}
							onClick={this.performRefund}
						>
							{submitting && <RotateLoader size={2} color="white" />}
							{!submitting && "Refund"}
						</Button>
					</FormGroup>
				</ModalContent>
			</Modal>
		);
	}
}

export const StripeConnectRefundModal = withTranslation()(StripeConnectRefundModalClass);
