import shortid from 'shortid';
import { nanoid } from 'nanoid';
import { GenericPromo, PromoType } from './type';

export const constructPlainPromoInstance = (
  type: PromoType
): GenericPromo | null => {
  switch (type) {
    case 'discount':
      return {
        _id: shortid.generate(),
        name: '',
        code: '',
        fixed_discount: 0,
        percent_discount: 0,
        free_delivery: false,
        min_order: '',
        max_uses: '',
        max_amount: 0,
        auto_apply: false,
        once_per_customer: false,
        logged_in_only: false,
        valid_times: [],
        services: [],
        payment_methods: [],
        disabled: false,
        stats: {
          used: 0,
        },
      };
    case 'free_item':
      return {
        _id: nanoid(),
        name: '',
        code: '',
        type: 'free_item',
        description: '',
        notification: '',
        condition: {
          id: nanoid(),
          type: 'item_quantity',
          lower_limit: 0,
          upper_limit: 0,
          eligible_items: {},
          free_items: {},
          free_quantity: 0,
        },
        restrictions: {
          service_type: [],
          payment_methods: [],
          order_times: [],
          once_per_customer: false,
          authenticated_user_only: false,
          valid_datetime_ranges: [],
          automatically_apply: false,
        },
      };
    default:
      return null;
  }
};

export const getPromoType = (
  promo: GenericPromo
): T.Schema.Restaurant.Promo.PromoType => {
  if ('type' in promo) return promo.type;
  return 'discount'; // for backward compatibility
};

export const promosByType = (
  restaurant: T.Schema.Restaurant.RestaurantSchema,
  type: T.Schema.Restaurant.Promo.PromoType | null
): GenericPromo[] => {
  switch (type) {
    case 'discount':
      return restaurant.promos;
    case 'free_item':
      return restaurant.free_item_promos || [];
    default:
      return [];
  }
};

export const getPaymentMethods = (
  restaurant: T.Schema.Restaurant.RestaurantSchema | null
): Array<{ value: string; label: string }> => {
  let paymentMethods = new Array<{ value: string; label: string }>();
  if (restaurant && restaurant.settings.payments) {
    Object.keys(restaurant.settings.payments).forEach(method => {
      if (
        restaurant.settings.payments[method]?.label &&
        restaurant.settings.payments[method]?.enabled
      ) {
        paymentMethods.push({
          value: method,
          label: restaurant.settings.payments[method]?.label || '',
        });
      }
    });
  }
  return paymentMethods;
};
