import React, { useState, useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import {
  Button,
  FormGroup,
  ModalContent,
  RotateLoader,
  TabSelect,
} from '@lib/components';
import { Modal } from '@lib/components';
import { LimitsTab } from './limits-tab';
import { ConditionTab } from './condition-tab';
import { FreeItemPromo } from '../promos/type';
import { UI } from '../../../../../../../../core/ui';
import { GeneralInformationTab } from './general-tab';
import { FreeItemPromoValidationSchema } from './validationSchema';
import { RestaurantForm } from '../../../../../../../../mobx/components/restaurant-form';

interface Props {
  type: 'edit' | 'create';
  initialValues: FreeItemPromo;
  close: () => void;
}

export const SettingsFreeItemPromosForm = (props: Props) => {
  const [tab, setTab] = useState('0');
  const { store } = useContext(MobXProviderContext);
	const restaurant = store.restaurant;
  const { close, initialValues: currentPromo } = props;
	const promoList: any[] = [...restaurant.promos, ...(restaurant.free_item_promos || [])];
	const promoCodeList = promoList.map(promo => promo.code.toLowerCase());
  const updatePromosQuery = (
    restaurant: T.Schema.Restaurant.RestaurantSchema,
    item: FreeItemPromo
  ) => {
    const promos = restaurant.free_item_promos || [];
    const idx = promos.findIndex(p => p._id === item._id);
    if (idx === -1) {
      promos.push(item);
    } else {
      promos[idx] = item;
    }
    restaurant.free_item_promos = promos;

    return {
      $set: { free_item_promos: promos },
    };
  };

  return (
    <Modal
      width="md"
      close={close}
      alignTop={true}
      active={!!currentPromo}
      id="free-item-promo"
    >
      <ModalContent className="">
        <h3 className="">{`${
          currentPromo.code ? 'Update' : 'Create'
        } Promo: Free Item`}</h3>
      </ModalContent>

      <TabSelect
        id="free-item-promo-tab"
        className="border-white-tb-10"
        hasBorder={true}
        screenWidth={store.view.screen_width}
        onChange={v => setTab(v.value)}
        value={tab}
        values={[
          { label: 'General', value: '0' },
          { label: 'Condition', value: '1' },
          { label: 'Limits', value: '2' },
        ]}
      />

      <RestaurantForm<FreeItemPromo>
        initialValues={currentPromo}
        validators={{
					code: values => {
						if (!currentPromo.code && promoCodeList.some(e => e === values.code.toLowerCase()))
							return { code: 'This code is already taken' };
						}
				}}
        validationSchema={FreeItemPromoValidationSchema}
        onSuccess={() => close()}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
        submit={async (restaurant, item) => ({
          r: restaurant,
          update: updatePromosQuery(restaurant, item),
        })}
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, errors } = form;
          return (
            <>
              {tab === '0' && (
                <GeneralInformationTab
                  form={form}
                  getFieldError={getFieldError}
                />
              )}

              {tab === '1' && (
                <ConditionTab form={form} getFieldError={getFieldError} />
              )}

              {tab === '2' && (
                <LimitsTab form={form} getFieldError={getFieldError} />
              )}

              <ModalContent>
                <Button
                  full={true}
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>

                {errors && Object.keys(errors).length > 0 && (
                  <FormGroup
                    no_border
                    error="Validation failed. Please check the promotion data again."
                  />
                )}
              </ModalContent>
            </>
          );
        }}
      </RestaurantForm>
    </Modal>
  );
};
