import axios from "axios";
import autobind from "autobind-decorator";
import { config } from "../../config";
import { RootStore } from "../store";

interface APIHandlers {
	auth_token_error: () => void;
}

@autobind
export class APIStore {
	readonly handlers: APIHandlers;
	store: RootStore;

	constructor(store: RootStore, handlers: APIHandlers) {
		this.store = store;
		this.handlers = handlers;
	}

	// API REQUEST HELPERS
	async request(args: T.API.RequestArgs): Promise<any> {
		const { path, data, params, headers } = args;
		try {
			const res = await axios({
				baseURL: config.urls.api + "/",
				url: path,
				method: "post",
				headers: headers || {},
				params: params || {},
				data: data || {},
			});
			return res.data;
		} catch (e) {
			if (e.response && e.response.data) {
				// === "error-auth-token"
				if (e.response.data.message === "Invalid Authentication") {
					this.handlers.auth_token_error();
					return { outcome: 1, message: "" };
				} else {
					throw e.response.data;
				}
			} else {
				throw e;
			}
		}
	}
	async request_public(args: T.API.RequestPublicPrivateArgs): Promise<any> {
		const { path, data, params } = args;
		const headers = {
			"Authorization-Public": config.services.api.public_key,
		};
		return this.request({ path, data, params, headers });
	}
	async request_auth(args: T.API.RequestPublicPrivateArgs): Promise<any> {
		const { path, data, params } = args;
		const token = this._get_auth_token();
		if (config.isTest && !token) return;
		const headers = { "Authorization-Dashboard": `${token}` };
		return this.request({ path, data, params, headers });
	}

	// RESELLER
	async reseller_find_public(
		data: T.API.ResellerFindPublicRequest
	): Promise<T.API.ResellerFindPublicResponse> {
		return this.request_public({
			path: "/resellers/find/dashboard/public",
			data: data,
		});
	}

	// USER
	async user_register_commence(
		data: T.API.UserRegisterCommenceRequest
	): Promise<T.API.UserRegisterCommenceResponse> {
		return this.request_public({
			path: "/users/register/commence",
			data: data,
		});
	}
	async user_register_complete(
		data: T.API.UserRegisterCompleteRequest
	): Promise<T.API.UserRegisterCompleteResponse> {
		return this.request_public({
			path: "/users/register/complete",
			data: data,
		});
	}
	async user_login(
		data: T.API.UserLoginRequest
	): Promise<T.API.UserLoginResponse> {
		return this.request_public({
			path: "/users/login",
			data: data,
		});
	}
	async user_password_reset_init(
		data: T.API.UserPasswordResetInitRequest
	): Promise<T.API.UserPasswordResetInitResponse> {
		return this.request_public({
			path: "/users/password-reset/init",
			data: data,
		});
	}
	async user_password_reset_change(
		data: T.API.UserPasswordResetChangeRequest
	): Promise<T.API.UserPasswordResetChangeResponse> {
		return this.request_public({
			path: "/users/password-reset/change",
			data: data,
		});
	}
	async user_token_refresh(): Promise<T.API.UserTokenRefreshResponse> {
		return this.request_auth({
			path: "/users/token/refresh",
		});
	}
	async user_profile_update(
		data: T.API.UserUpdateProfileRequest
	): Promise<T.API.UserUpdateProfileResponse> {
		return this.request_auth({
			path: "/users/update/profile",
			data: data,
		});
	}

	// RESTAURANT
	async restaurant_create(
		data: T.API.RestaurantCreateRequest
	): Promise<T.API.RestaurantCreateResponse> {
		return this.request_auth({
			path: "/restaurants/create",
			data: data,
		});
	}
	async restaurant_update(
		data: T.API.RestaurantUpdateRequest
	): Promise<T.API.RestaurantUpdateResponse> {
		return this.request_auth({
			path: "/restaurants/update",
			data: data,
		});
	}
	async restaurant_update_subscription(
		data: T.API.RestaurantUpdateSubscriptionRequest
	): Promise<T.API.RestaurantUpdateSubscriptionResponse> {
		return this.request_auth({
			path: "/restaurants/update/subscription",
			data: data,
		});
	}
	async restaurant_delete(
		data: T.API.RestaurantDeleteRequest
	): Promise<T.API.RestaurantDeleteResponse> {
		return this.request_auth({
			path: "/restaurants/delete",
			data: data,
		});
	}

	// PROXY
	async proxy_subdomain_check(
		data: T.API.ProxySubdomainCheckUpdateRequest
	): Promise<T.API.ProxySubdomainCheckUpdateResponse> {
		return this.request_auth({
			path: "/proxy/subdomain/check",
			data: data,
		});
	}
	async proxy_subdomain_update(
		data: T.API.ProxySubdomainCheckUpdateRequest
	): Promise<T.API.ProxySubdomainCheckUpdateResponse> {
		return this.request_auth({
			path: "/proxy/subdomain/update",
			data: data,
		});
	}
	async proxy_domain_check(
		data: T.API.ProxyDomainCheckUpdateRequest
	): Promise<T.API.ProxyDomainCheckUpdateResponse> {
		return this.request_auth({
			path: "/proxy/domain/check",
			data: data,
		});
	}
	async proxy_domain_update(
		data: T.API.ProxyDomainCheckUpdateRequest
	): Promise<T.API.ProxyDomainCheckUpdateResponse> {
		return this.request_auth({
			path: "/proxy/domain/update",
			data: data,
		});
	}
	async proxy_domain_cert_check(
		data: T.API.ProxyCertCheckRequest
	): Promise<T.API.ProxyCertCheckResponse> {
		return this.request_auth({
			path: "/proxy/cert/check",
			data: data,
		});
	}
	async proxy_domain_cert_issue(
		data: T.API.ProxyCertIssueRequest
	): Promise<T.API.ProxyCertIssueResponse> {
		return this.request_auth({
			path: "/proxy/cert/issue",
			data: data,
		});
	}

	// STAFF
	async staff_find(
		data: T.API.StaffFindRequest
	): Promise<T.API.StaffFindResponse> {
		return this.request_auth({
			path: "/dashboard/staff/find",
			data: data,
		});
	}
	async staff_create(
		data: T.API.StaffCreateRequest
	): Promise<T.API.StaffCreateResponse> {
		return this.request_auth({
			path: "/dashboard/staff/create",
			data: data,
		});
	}
	async staff_update(
		data: T.API.StaffUpdateRequest
	): Promise<T.API.StaffUpdateResponse> {
		return this.request_auth({
			path: "/dashboard/staff/update",
			data: data,
		});
	}
	async staff_delete(
		data: T.API.StaffDeleteRequest
	): Promise<T.API.StaffDeleteResponse> {
		return this.request_auth({
			path: "/dashboard/staff/delete",
			data: data,
		});
	}

	// API ACCESS
	async api_find(data: T.API.APIFindRequest): Promise<T.API.APIFindResponse> {
		return this.request_auth({
			path: "/dashboard/api/find",
			data: data,
		});
	}
	async api_create(
		data: T.API.APICreateRequest
	): Promise<T.API.APICreateResponse> {
		return this.request_auth({
			path: "/dashboard/api/create",
			data: data,
		});
	}
	async api_update(
		data: T.API.APIUpdateRequest
	): Promise<T.API.APIUpdateResponse> {
		return this.request_auth({
			path: "/dashboard/api/update",
			data: data,
		});
	}
	async api_delete(
		data: T.API.APIDeleteRequest
	): Promise<T.API.APIDeleteResponse> {
		return this.request_auth({
			path: "/dashboard/api/delete",
			data: data,
		});
	}

	// WEBSITE
	async website_find(
		data: T.API.WebsiteFindRequest
	): Promise<T.API.WebsiteFindResponse> {
		return this.request_auth({
			path: "/website/dashboard/find",
			data: data,
		});
	}
	async website_create(
		data: T.API.DashboardWebsiteCreateRequest
	): Promise<T.API.DashboardWebsiteCreateResponse> {
		return this.request_auth({
			path: "/website/dashboard/create",
			data: data,
		});
	}
	async website_update(
		data: T.API.DashboardWebsiteUpdateRequest
	): Promise<T.API.DashboardWebsiteUpdateResponse> {
		return this.request_auth({
			path: "/website/dashboard/update",
			data: data,
		});
	}

	// DASHBOARD
	async dashboard_data(): Promise<T.API.DashboardDataResponse> {
		return this.request_auth({
			path: "/dashboard/data",
		});
	}
	async restaurants(): Promise<T.API.DashboardRestaurantsResponse> {
		return this.request_auth({
			path: "/dashboard/restaurants",
		});
	}
	async restaurant(
		data: T.API.DashboardRestaurantFindRequest
	): Promise<T.API.DashboardRestaurantFindResponse> {
		return this.request_auth({
			path: "/dashboard/restaurant",
			data: data,
		});
	}
	async billing_session(
		data: T.API.DashboardBillingSessionRequest
	): Promise<T.API.DashboardBillingSessionResponse> {
		return this.request_auth({
			path: "/dashboard/billing/session",
			data: data,
		});
	}

	// DASHBOARD - RESTAURANT SPECIFIC
	async reports_basic(
		data: T.API.DashboardReportsBasicRequest
	): Promise<T.API.DashboardReportsBasicResponse> {
		return this.request_auth({
			path: "/dashboard/reports/basic",
			data: data,
		});
	}

	async send_overview_report_email(
		data: T.API.DashboardReportsSendingEmailRequest
	): Promise<T.API.DashboardReportsSendingEmailResponse> {
		return this.request_auth({
			path: "/dashboard/reports/sendEmail",
			data,
		});
	}

	async menu_stock_find(
		data: T.API.DashboardMenuStockFindReq
	): Promise<T.API.DashboardMenuStockFindRes> {
		return this.request_auth({
			path: "/dashboard/menu/stock/find",
			data: data,
		});
	}
	// Kounta Requests
	async get_kounta_sites(_id: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/sites",
			data: { _id: _id },
		});
	}
	async get_kounta_registers(_id: any, site_id: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/registers",
			data: { _id: _id, site_id: site_id },
		});
	}
	async get_kounta_payments(_id: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/payment_methods",
			data: { _id: _id },
		});
	}
	async get_kounta_delivery_products(_id: any, site_id: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/delivery_products",
			data: { _id: _id, site_id: site_id },
		});
	}
	async generate_menu(_id: any, menu_id: string): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/generate_menu",
			data: { _id: _id, menu_id: menu_id },
		});
	}
	async generate_menuv2(_id: any, menu_id: string): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/generate_menu_v2",
			data: { _id: _id, menu_id: menu_id },
		});
	}
	async generate_menu_abacus(_id: any, menu_id: string): Promise<any> {
		return this.request_auth({
			path: "/dashboard/abacus/generate_menu",
			data: { _id: _id, menu_id: menu_id },
		});
	}
	async check_status(_id: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/kounta/check_status",
			data: { _id: _id },
		});
	}
	async check_status_abacus(_id: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/abacus/check_status",
			data: { _id: _id },
		});
	}
	async menu_stock_update(
		data: T.API.DashboardMenuStockUpdateReq
	): Promise<T.API.DashboardMenuStockUpdateRes> {
		return this.request_auth({
			path: "/dashboard/menu/stock/update",
			data: data,
		});
	}

	async orders_board_find(
		data: T.API.DashboardOrdersBoardFindRequest
	): Promise<T.API.DashboardOrdersBoardFindResponse> {
		return this.request_auth({
			path: "/dashboard/orders/board",
			data: data,
		});
	}
	async orders_find(
		data: T.API.DashboardOrdersFindRequest
	): Promise<T.API.DashboardOrdersFindResponse> {
		return this.request_auth({
			path: "/dashboard/orders",
			data: data,
		});
	}
	async order_find(
		data: T.API.DashboardOrderFindRequest
	): Promise<T.API.DashboardOrderFindResponse> {
		return this.request_auth({
			path: "/dashboard/order",
			data: data,
		});
	}
	async order_update_status(
		data: T.API.DashboardOrderUpdateStatusRequest
	): Promise<T.API.DashboardOrderUpdateStatusResponse> {
		return this.request_auth({
			path: "/dashboard/order/update/status",
			data: data,
		});
	}
	async order_update_ready_time(
		data: T.API.DashboardOrderUpdateReadyTimeRequest
	): Promise<T.API.DashboardOrderUpdateReadyTimeResponse> {
		return this.request_auth({
			path: "/dashboard/order/update/ready-time",
			data: data,
		});
	}
	async order_book_tookan(
		data: T.API.DashboardOrderTookanBookRequest
	): Promise<T.API.DashboardOrderTookanBookResponse> {
		return this.request_auth({
			path: "/dashboard/order/tookan/book",
			data: data,
		});
	}
	async order_delete(
		data: T.API.DashboardOrderDeleteRequest
	): Promise<T.API.DashboardOrderDeleteResponse> {
		return this.request_auth({
			path: "/dashboard/order/delete",
			data: data,
		});
	}
	async order_stripe_refund(
		data: T.API.DashboardOrderStripeRefundRequest
	): Promise<T.API.DashboardOrderStripeRefundResponse> {
		return this.request_auth({
			path: "/dashboard/order/stripe/refund",
			data: data,
		});
	}
	async order_stripe_connect_refund(
		data: T.API.DashboardOrderStripeConnectRefundRequest
	): Promise<T.API.DashboardOrderStripeRefundResponse> {
		return this.request_auth({
			path: "/dashboard/order/stripe-connect/refund",
			data: data,
		});
	}
	async order_cardconnect_refund(
		data: T.API.DashboardOrderCardConnectRefundRequest
	): Promise<T.API.DashboardOrderCardConnectRefundResponse> {
		return this.request_auth({
			path: "/dashboard/order/cardconnect/refund",
			data: data,
		});
	}
	async order_verify_checkout_payment(
		data: T.API.DashboardOrderCheckoutPaymentVerificationRequest
	): Promise<T.API.DashboardOrderCheckoutPaymentVerificationResponse> {
		return this.request_auth({
			path: "/dashboard/order/checkout/verify-payment",
			data,
		});
	}

	async orderVerifyPaywayPayment(
		data: T.API.DashboardOrderPaywayVerificationRequest
	): Promise<T.API.DashboardOrderPaywayVerificationResponse> {
		return this.request_auth({
			path: "/dashboard/order/payway/verify-payment",
			data,
		});
	}

	async order_verify_gkash_payment(
		data: T.API.DashboardOrderGkashPaymentVerificationRequest
	): Promise<T.API.DashboardOrderGkashPaymentVerificationResponse> {
		return this.request_auth({
			path: "/dashboard/order/gkash/verify-payment",
			data,
		});
	}

	async bookings_find(
		data: T.API.DashboardBookingsFindRequest
	): Promise<T.API.DashboardBookingsFindResponse> {
		return this.request_auth({
			path: "/dashboard/bookings",
			data: data,
		});
	}
	async booking_find(
		data: T.API.DashboardBookingFindRequest
	): Promise<T.API.DashboardBookingFindResponse> {
		return this.request_auth({
			path: "/dashboard/booking",
			data: data,
		});
	}
	async booking_update_status(
		data: T.API.DashboardBookingUpdateStatusRequest
	): Promise<T.API.DashboardBookingUpdateStatusResponse> {
		return this.request_auth({
			path: "/dashboard/booking/update/status",
			data: data,
		});
	}
	async booking_delete(
		data: T.API.DashboardBookingDeleteRequest
	): Promise<T.API.DashboardBookingDeleteResponse> {
		return this.request_auth({
			path: "/dashboard/booking/delete",
			data: data,
		});
	}

	async print_receipt(
		data: T.API.DashboardPrintRequest
	): Promise<T.API.DashboardPrintResponse> {
		return this.request_auth({
			path: "/printing/dashboard/print",
			data: data,
		});
	}

	async print_report(
		data: T.API.DashboardReportsBasicRequest
	): Promise<T.API.DashboardPrintResponse> {
		return this.request_auth({
			path: "/printing/dashboard/report/print",
			data,
		});
	}

	async customers_find(
		data: T.API.DashboardCustomersFindRequest
	): Promise<T.API.DashboardCustomersFindResponse> {
		return this.request_auth({
			path: "/dashboard/customers",
			data: data,
		});
	}
	async customer_find(
		data: T.API.DashboardCustomerFindRequest
	): Promise<T.API.DashboardCustomerFindResponse> {
		return this.request_auth({
			path: "/dashboard/customer",
			data: data,
		});
	}
	async customer_create(
		data: T.API.DashboardCustomerCreateRequest
	): Promise<T.API.DashboardCustomerCreateResponse> {
		return this.request_auth({
			path: "/dashboard/customer/create",
			data: data,
		});
	}
	async customer_update_email(
		data: T.API.DashboardCustomerUpdateEmailRequest
	): Promise<T.API.DashboardCustomerUpdateEmailResponse> {
		return this.request_auth({
			path: "/dashboard/customer/update/email",
			data: data,
		});
	}
	async customer_update_password(
		data: T.API.DashboardCustomerUpdatePasswordRequest
	): Promise<T.API.DashboardCustomerUpdatePasswordResponse> {
		return this.request_auth({
			path: "/dashboard/customer/update/password",
			data: data,
		});
	}
	async customer_age_verification_update(
		data: T.API.DashboardCustomerAgeVerificationUpdateRequest
	): Promise<T.API.DashboardCustomerAgeVerificationUpdateResponse> {
		return this.request_auth({
			path: "/dashboard/customer/age-verification/update",
			data: data,
		});
	}
	async customer_delete(
		data: T.API.DashboardCustomerDeleteRequest
	): Promise<T.API.DashboardCustomerDeleteResponse> {
		return this.request_auth({
			path: "/dashboard/customer/delete",
			data: data,
		});
	}
	async dashboard_restaurant_domain_verify(
		data: T.API.DashboardRestaurantDomainVerificationRequest
	): Promise<T.API.DashboardRestaurantDomainVerificationResponse> {
		return this.request_auth({
			path: "/dashboard/restaurant/domain/verify",
			data: data,
		});
	}
	async dashboard_restaurant_payment_prefill_stripedigitalpay(
		data: T.API.DashboardPaymentPrefillStripeDigitalPayRequest
	): Promise<T.API.DashboardPaymentPrefillStripeDigitalPayResponse> {
		return this.request_auth({
			path: "/dashboard/restaurant/payment/prefill/stripe_digital_pay",
			data: data,
		});
	}

	async printer_status(
		data: T.API.DashboardPrinterStatusReq
	): Promise<T.API.DashboardPrinterStatusRes> {
		return this.request_auth({
			path: "/dashboard/printers/status",
			data: data,
		});
	}

	async getStripeConnect(data: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/connect/get-transactions",
			data: data,
		});
	}

	async stripe_connect_order_find(data: any): Promise<any> {
		return this.request_auth({
			path: "/dashboard/connect/transactions",
			data: data,
		});
	}

	async stripe_connect_payout_list(data: any): Promise<any> {
		return await this.request_auth({
			path: "/v2/stripe/payout/list",
			data: data,
		});
	}

	async stripe_connect_payout_details(data: any): Promise<any> {
		return await this.request_auth({
			path: "/v2/stripe/payout/get-transactions",
			data: data,
		});
	}

	async stripe_connect_payout_request_report(data: any): Promise<any> {
		return await this.request_auth({
			path: "/v2/stripe/payout/request-report",
			data: data,
		});
	}

	async create_lalamove_quotation(
		data: T.API.CreateLalamoveQuoteRequest
	): Promise<T.API.CreateLalamoveQuoteResponse> {
		return this.request_auth({
			path: "/dashboard/order/lalamove/delivery-quotes",
			data,
		});
	}

	async create_lalamove_delivery(
		data: T.API.DashboardCreateLalamoveDeliveryRequest
	): Promise<T.API.DashboardCreateLalamoveDeliveryResponse> {
		return this.request_auth({
			path: "/dashboard/order/lalamove/deliveries",
			data,
		});
	}

	async findOrderByLalamoveDeliveryId(
		data: T.API.DashboardOrderFindByLalamoveOrderIdRequest
	): Promise<T.API.DashboardOrderFindResponse> {
		return this.request_auth({
			path: "/dashboard/order/find-by-lalamove-order-id",
			data,
		});
	}

	// HELPERS
	private _get_auth_token(): string {
		return this.store.auth.token || "";
	}
}
