import * as React from "react";
import { MobxComponent } from "../../../mobx/components/index";
import { TopNav } from "@lib/components";
import { LayoutDashboardComponents } from "./layout.c";
import { inject, observer } from "mobx-react";
import { Tooltip } from "@lib/components";
import { FaLifeRing, FaPowerOff, FaRocket } from "react-icons/fa";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
	children: React.ReactNode;
}
interface State {

}

@inject("store") @observer
class LayoutDashboardClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	render() {
		const { store, t } = this.injected;
		const reseller = store.reseller!;
		const pathname = store.router.s.path;
		const { trialExpiry, trialExpired, restrictions, isStaff, isMainReseller } = store;
		const { children } = this.props;
		const { logo_icon } = reseller.admin;
		const links = [];

		if (
			restrictions.restaurant.create ||
			restrictions.restaurant.delete ||
			restrictions.restaurant.billing ||
			restrictions._.restaurantView
		) {
			links.push({
				text: "Restaurants",
				onClick: () => store.router.push("/"),
				active: pathname === "/",
			});
		}

		if (restrictions.website.create || restrictions.website.edit) {
			links.push({
				text: "Landing Page",
				onClick: () => store.router.push("/website"),
				active: pathname === "/website",
			});
		}

		if (restrictions.staff.create || restrictions.staff.edit || restrictions.staff.delete) {
			links.push({
				text: "Staff",
				onClick: () => store.router.push("/staff"),
				active: pathname === "/staff",
			});
		}

		if (restrictions.api && (restrictions.api.create || restrictions.api.edit || restrictions.api.delete)) {
			links.push({
				text: "Developers",
				onClick: () => store.router.push("/developer"),
				active: pathname === "/developer",
			});
		}

		return (
			<div>

				<TopNav.Wrapper>
					<TopNav.Content width="lg" align="space-between">

						<div className="flex-line centered">

							<TopNav.Logo className="m-r-4">
								<img src={logo_icon} />
							</TopNav.Logo>

							<TopNav.Menu
								align="left"
								items={links}
							/>

						</div>

						<div className="flex-line centered child-mr-7">
							{(!trialExpired && !isStaff) && (
								<Tooltip
									width={200}
									position="bottom"
									offset={10}
									text={<p className="lhp p-1">Ensure all your restaurants have a payment method to prevent issues</p>}>
									<p className="">
										Trial ends {t("dateFromTimestamp", { value: trialExpiry })}
									</p>
								</Tooltip>
							)}
							{isMainReseller && (
								<Tooltip
									text="Documentation"
									width={120}
									position="bottom"
									offset={5}>
									<a href="https://support.cloudwaitress.com" target="_blank">
										<TopNav.Icon>
											<FaLifeRing />
										</TopNav.Icon>
									</a>
								</Tooltip>
							)}
							{isMainReseller && (
								<Tooltip
									text={<span className="inline-block lhp p-lr-1">Roadmap & feature requests</span>}
									width={120}
									position="bottom"
									offset={5}>
									<a href="https://feedback.cloudwaitress.com/roadmap" target="_blank">
										<TopNav.Icon>
											<FaRocket />
										</TopNav.Icon>
									</a>
								</Tooltip>
							)}
							<Tooltip text="Logout" width={60} position="bottom" offset={5}>
								<TopNav.Icon onClick={() => store.service.logout()}>
									<FaPowerOff />
								</TopNav.Icon>
							</Tooltip>
						</div>

					</TopNav.Content>
				</TopNav.Wrapper>

				<LayoutDashboardComponents.Content>
					{children}
				</LayoutDashboardComponents.Content>

			</div>
		);
	}

}

export const LayoutDashboard = withTranslation()(LayoutDashboardClass);
