import * as Yup from 'yup';

export const ConditionValidationSchema = Yup.object().shape({
  id: Yup.string().required().label('ID'),
  type: Yup.string().oneOf(['item_quantity', 'order_amount']).label('Type'),
  lower_limit: Yup.number()
    .min(1)
    .max(Yup.ref('upper_limit'))
    .label('Lower Limit'),
  upper_limit: Yup.number().min(1).label('Upper Limit'),
  eligible_items: Yup.object()
    .when('type', {
      is: 'item_quantity',
      then: schema =>
        schema
          .shape({
            menus: Yup.array(),
            categories: Yup.array(),
            items: Yup.array(),
          })
          .noUnknown(),
      otherwise: schema => schema.optional(),
    })
    .label('Eligible Items'),
  free_items: Yup.object()
    .shape({
      menus: Yup.array(),
      categories: Yup.array(),
      items: Yup.array(),
    })
    .noUnknown()
    .label('Free Items'),
  free_quantity: Yup.number().min(1).required().label('Free Quantity'),
});

const DateTimeRange = Yup.object().shape({
  start: Yup.number()
    .required()
    .lessThan(Yup.ref('end'))
    .label('Datetime range start'),
  end: Yup.number().required().label('Datetime range end'),
});

export const FreeItemPromoValidationSchema = Yup.object().shape({
  _id: Yup.string().required(),
  name: Yup.string().required().label('Name'),
  code: Yup.string().required().label('Promo Code'),
  type: Yup.string().oneOf(['free_item']),
  description: Yup.string().nullable().label('Description'),
  notification: Yup.string().nullable().label('Notification'),
  condition: ConditionValidationSchema.label('Condition'),
  restrictions: Yup.object().shape({
    service_type: Yup.array().label('Service Type'),
    payment_methods: Yup.array().label('Payment Methods'),
    order_times: Yup.array().label('Order Times'),
    once_per_customer: Yup.boolean().label('Once Per Customer'),
    authenticated_user_only: Yup.boolean().label('Authenticated User Only'),
    valid_datetime_ranges: Yup.array()
      .of(DateTimeRange)
      .label('Valid Datetime Ranges'),
    automatically_apply: Yup.boolean().label('Automatically Apply'),
  }),
});
