import * as React from "react";
import { FastField } from "formik";
import { FormGroup, Switch } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { ModalContent } from "@lib/components";
import { Modal } from "@lib/components";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { TabSelect, SelectAdv, FieldOpeningHours } from "@lib/components";
import { DataLocales, ListServicesOptionsNoB } from "@lib/common";
import _cloneDeep from "lodash/cloneDeep";

type FormValues = T.Schema.Restaurant.Menu.RestaurantCategory;

interface Props {
	type: "edit" | "create";
	menuIndex: number;
	initialValues: FormValues | null;
	close: () => void;
}

interface State {
	tab: string;
}

@inject("store") @observer
export class RestaurantFormCategory extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
		};
	}

	setTab = (tab: string) => {
		this.setState({ tab });
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValues && !prevProps.initialValues) {
			this.setTab("0");
		}
	}

	render() {
		const { type, close } = this.props;
		const { tab } = this.state;

		const initialValues = _cloneDeep(this.props.initialValues);

		if (initialValues && !initialValues.conditions) {
			initialValues.conditions = {
				hide_unavailable_category: false,
				services: [],
				hours: [],
			};
		}

		let openItems = false;
		let openItemsList: any = [];
		const r = this.injected.store.restaurant;
		// console.log(1)
		if (r && initialValues) {
			// console.log(2)
			// console.log({ initialValues });
			// const filteredMenus = r.menus.filter((menu: any) => menu._id === initialValues.menu_id);
			// if (filteredMenus.length > 0) {
				if (
					// filteredMenus[0]
					// && typeof filteredMenus[0].abacus_open_items === "boolean"
					// && filteredMenus[0].abacus_open_items === true
					r.abacus
					&& typeof r.abacus.abacus_open_items === "boolean"
					&& r.abacus.abacus_open_items === true
				) {
					// console.log(3)
					openItems = true;
					if (
						// filteredMenus[0]
						// && filteredMenus[0].abacus_open_item_list
						r.abacus.abacus_open_item_list
						&& r.abacus.abacus_open_item_list.length > 0
					)
					{
						// console.log(4)
						const filteredList = r.abacus.abacus_open_item_list.filter(openItem => !['Online Promos', 'Online Fees', 'Online Tips'].includes(openItem.productName))
						openItemsList = filteredList.map(openItemListItem => {
							return {
								value: openItemListItem.productId,
								label: openItemListItem.productName
							}
						});
					}
				}
			// }
		}
		// console.log({ openItems });
		//
		const menuTabs = openItems
			? [
				{ label: "General", value: "0" },
				{ label: "Conditions", value: "1" },
				{ label: "Open Items", value: "2" },
			]
			: [
				{ label: "General", value: "0" },
				{ label: "Conditions", value: "1" },
			];
		//
		return (
			<Modal
				width="md"
				alignTop={true}
				active={!!initialValues}
				close={close}
			>
				<ModalContent>
					<h3 className="">
						{type === "create" ? "Create Category" : "Edit Category"}
					</h3>
					{type === "edit" && initialValues && (
						<p className="m-t-1">{initialValues.name}</p>
					)}
				</ModalContent>

				<TabSelect
					id="menu-tab-options"
					className="border-white-tb-15"
					hasBorder={true}
					screenWidth={this.injected.store.view.screen_width}
					onChange={(v) => this.setTab(v.value)}
					value={tab}
					values={menuTabs}
				/>

				<RestaurantForm<FormValues>
					submit={async (r, values) => {
						const { menuIndex } = this.props;
						const existing = r.menus[menuIndex].categories.findIndex(
							(p) => p._id === values._id,
						);
						if (existing === -1) {
							r.menus[menuIndex].categories.push(values);
						} else {
							r.menus[menuIndex].categories[existing] = values;
						}
						const update = { $set: { menus: r.menus } };
						return { r, update };
					}}
					validators={{
						name: (values) => {
							if (!values.name) return { name: "This field is required" };
						},
					}}
					initialValues={initialValues}
					onSuccess={close}
					onSuccessMessage={
						type === "edit" ? "Category edited" : "Category created"
					}
					onErrorMessage=""
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue } = form;
						return (
							<div>
								{tab === "0" && (
									<ModalContent>
										<FastField
											name="name"
											render={({ field }: any) => (
												<FormGroup
													title="Name"
													help="A unique name for your category"
													error={getFieldError(form, "name")}
												>
													<Input type="text" {...field} required={true} />
												</FormGroup>
											)}
										/>
										<FastField
											name="display_name"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Display Name"
													help="Will override the unique name in your store"
													error={getFieldError(form, "display_name")}
												>
													<Input type="text" {...field} />
												</FormGroup>
											)}
										/>
										<FastField
											name="description"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Description"
													help="Tell your customers more details about this category"
													error={getFieldError(form, "description")}
												>
													<Input type="text" {...field} />
												</FormGroup>
											)}
										/>
									</ModalContent>
								)}

								{tab === "1" && (
									<ModalContent>
										<FastField
											name="conditions.hide_unavailable_category"
											render={({ field }: any) => (
												<FormGroup
													title="Hide Unavailable Category"
													help="Enabling this will hide this category in your menu if it's unavailable. Otherwise, this category will still be displayed with a warning message that it is not available."
												>
													<Switch
														id="enabled-hide-unavailable-category"
														checked={field.value || false}
														onChange={(e) =>
															setFieldValue("conditions.hide_unavailable_category", e.target.checked)
														}
													/>
												</FormGroup>
											)}
										/>

										<FastField
											name="conditions.services"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Services"
													help="Select which services this category will be available for. Leave empty to apply for all services. Please double-check with the parent menu's Services setting in Conditions tab because it has a higher priority when any conflict happened."
												>
													<SelectAdv
														type="multi"
														value={field.value}
														onChange={(options: string[]) => {
															setFieldValue("conditions.services", options);
														}}
														options={ListServicesOptionsNoB}
													/>
												</FormGroup>
											)}
										/>

										<FastField
											name="conditions.hours"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Applicable Hours"
													help="Set which hours this category will be available for. If no hours entered, the category is applicable at all times. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open. Please double-check with the parent menu's Applicable Hours setting in Conditions tab because it has a higher priority when any conflict happened."
													error={getFieldError(form, "hours")}
												>
													<div className="m-tb-3 block">
														<FieldOpeningHours
															allowNone={true}
															hours={field.value}
															onChange={(opening_hours) =>
																setFieldValue(
																	"conditions.hours",
																	opening_hours,
																)
															}
														/>
													</div>
												</FormGroup>
											)}
										/>
									</ModalContent>
								)}

								{tab === "2" && (
									<ModalContent>
										<FastField
											name="abacus_open_item"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Abacus Open Item"
													help="Select which Abacus Open Item the dishes in this category should belong to"
												>
													<SelectAdv
														type="single"
														options={openItemsList}
														value={field.value}
														onChange={(option: string) => setFieldValue("abacus_open_item", option)}
													/>
												</FormGroup>
											)}
										/>

									</ModalContent>
								)}

								<ModalContent>
									{error && <FormGroup error={error} />}
									<Button
										full={true}
										color="primary"
										type="submit"
										disabled={isSubmitting}
									>
										{isSubmitting && (
											<RotateLoader size={2} color="white" />
										)}
										{!isSubmitting && "Save"}
									</Button>
								</ModalContent>
							</div>
						);
					}}
				</RestaurantForm>
			</Modal>
		);

	}

}
