import * as React from "react";
import { FastField } from "formik";
import {
	FormGroup,
	ButtonGroup,
	Button,
	RotateLoader,
	Switch,
	SelectAdv,
} from "@lib/components";

import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../../core/ui";
import { Input } from "@lib/components";
import { ListServicesOptionsNoB } from "@lib/common";

interface Props { }
interface State { }
interface FormValues {
	business: T.Schema.Restaurant.RestaurantSchema["settings"]["business"];
}

@inject("store") @observer
export class SettingsFormTipping extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {};
		const r = this.injected.store.restaurant!;
		this.initialValues = {
			business: r.settings.business,
		};
		if (this.initialValues.business.tipping == undefined
			|| this.initialValues.business.tipping == null) {
			this.initialValues.business.tipping = {
				enabled: false,
				option_1: 10,
				option_2: 15,
				option_3: 20,
				default: 0,

			}
		}
	}

	render() {

		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.settings.business = values.business;
					const update = { $set: { "settings.business": values.business } };
					return { r, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FormGroup
								title="Order Tipping"
								help="Enable this to allow customers to leave tips when placing an order">
								<FastField
									name="business.tipping.enabled"
									render={({ field }: any) => (
										<Switch
											id="business.tipping.enabled"
											checked={field.value || false}
											onChange={(e) => setFieldValue("business.tipping.enabled", e.target.checked)} />
									)}
								/>
							</FormGroup>

							<FastField
								name="business.tipping.disabled_services"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Disabled Services"
										help="Add service above to disable tipping for that service. If blank, tipping will be enabled on all services."
									>
										<SelectAdv
											type="multi"
											value={field.value || []}
											onChange={(options: string[]) => {
												setFieldValue(
													"business.tipping.disabled_services",
													options
												);
											}}
											options={ListServicesOptionsNoB}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="business.tipping.default"
								render={({ field }: any) => (
									<FormGroup
										title="Tip Default"
										help="Which tip should be defaulted at checkout">
										<ButtonGroup
											size={"xs"}
											selected={field.value}
											options={[
												{ value: 0, name: "None" },
												{ value: 1, name: "1" },
												{ value: 2, name: "2" },
												{ value: 3, name: "3" },
											]}
											buttonClassName="p-lr-2"
											onSelect={(v) => {
												setFieldValue("business.tipping.default", v.value);
											}}
											width="auto"
										/>
									</FormGroup>
								)}
							/>

							<FormGroup
								title="Tip 1 Amount (%)"
							>
								<FastField
									name="business.tipping.option_1"
									render={({ field }: any) => (
										<Input style={{ width: "120px" }} size={"sm"} type="number" {...field} />
									)}
								/>
							</FormGroup>

							<FormGroup
								title="Tip 2 Amount (%)"
							>
								<FastField
									name="business.tipping.option_2"
									render={({ field }: any) => (
										<Input style={{ width: "120px" }} size={"sm"} type="number" {...field} />
									)}
								/>
							</FormGroup>

							<FormGroup
								title="Tip 3 Amount (%)"
							>
								<FastField
									name="business.tipping.option_3"
									render={({ field }: any) => (
										<Input style={{ width: "120px" }} size={"sm"} type="number" {...field} />
									)}
								/>
							</FormGroup>

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</RestaurantForm>
		);
	}

}
